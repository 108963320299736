@use "sass:math";

$font-primary: 'Helvetica Neue', Arial, Helvetica, Verdana,
  sans-serif;
$header-font: 'Helvetica Neue', Arial, Helvetica, Verdana,
  sans-serif;
$header-font-bold: 'Helvetica Neue', Arial, Helvetica, Verdana,
  sans-serif;

$bg-color--soft-white: #f7f7f7;

/*
 * Header config
 */
$header_height: 72px;
$header_mobile_padding: 20px;
$header_socials_height: 30px;
$header_bg_color: #252525;
$header_font_color: white;
//!

/*
 * Spacing
 */
$base-font-size: 13px !default;
$base-font-size-rem: 1rem !default;
$base-line-height: 16px !default;

$base-spacing-unit: 20px !default;
$half-spacing-unit: 12px !default;
$quarter-spacing-unit: math.div($half-spacing-unit, 2) !default;
$line-height-ratio: math.div($base-line-height, $base-font-size);

/*
 * Others
 */
$brand-radius: 3px;

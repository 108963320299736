.img-left {
  float: left;
  margin: 0 12px 12px 0;
}

.img-right {
  float: right;
  margin: 12px 0 0 12px;
}

.img-center {
  text-align: center;
  margin: 0 auto 12px auto;
}

figure {
  figcaption {
    padding: 4px 0;
    font-size: 0.8em;
    line-height: 2em;
    font-style: italic;
    text-align: center;
    width: fit-content;
    max-width: fit-content;
    word-wrap: break-word;
    min-width: 100%;
  }
}

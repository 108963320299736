.bloc-item-container {
  padding-left: 0;
  .cover-bloc-item--news {
    box-shadow: none;
    &.cover-bloc-item--news--index {
      box-shadow: none;
    }
  }

}

.grid--news {
  & > :nth-child(2n - 1) {
    padding-left: 0;
  }
  & > :nth-child(2n) {
    padding-left: 30px;
  }
}

.cover-bloc-item--news {
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  transition: transform 0.24s, box-shadow 0.24s, -webkit-transform 0.24s;
  box-shadow: 6px 6px 21px rgba(0, 0, 0, 0.3);

  a {
    text-decoration: none;

    &:focus h4,
    &:focus .h4 {
      text-decoration: underline;
    }
  }

  &:hover {
    .cover-bloc-item__image {
      transform: scale(1.04);

      &::after {
        opacity: 0.45;
      }
    }
  }

  &-aa {
    &:hover {
      .cover-bloc-item__image {
        transform: scale(1.04);

        &::after {
          opacity: 0.9;
        }
      }
    }
  }

  .cover-bloc-item__image {
    display: block;
    height: 21rem;
    overflow: hidden;
    width: 100%;
    transition: transform 0.9s;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6));
      z-index: 1;
      content: ' ';
      -webkit-transition: opacity 0.5s cubic-bezier(0.9, 0.26, 0.58, 1) false;
      -moz-transition: opacity 0.5s cubic-bezier(0.9, 0.26, 0.58, 1) false false;
      -o-transition: opacity 0.5s cubic-bezier(0.9, 0.26, 0.58, 1) false false;
      transition: opacity 0.5s cubic-bezier(0.9, 0.26, 0.58, 1);
    }

    &-aa {
      &::after {
        background: linear-gradient(rgba(0, 0, 0, 0.55), rgba(0, 0, 0, 0.65));
      }
    }
  }

  .cover-bloc-item__header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 37px;
    padding: 0 5px;
  }

  .cover-bloc-item__footer {
    display: flex;
    flex-flow: column;
    height: 10.4rem;
    position: absolute;
    bottom: 0;
    transition: transform 0.5s cubic-bezier(0.9, 0.26, 0.58, 1);
    transition-delay: 0.1s;
    z-index: 2;
    width: 85%;
    flex: 1 1 auto;
    padding: 1.2rem 1.4rem;

    @include media-query(palm) {
      width: 100%;
    }
  }

  .cover-bloc-item__info {
    h4,
    .h4 {
      opacity: 0;
      transition: opacity 0.1s cubic-bezier(0.9, 0.26, 0.58, 1);
      color: #ffffff;
      height: 5.5rem;
      word-break: normal;
      font-size: 1.8em;
      font-weight: 600;
      margin-bottom: 12px;
    }

    .moment {
      text-transform: lowercase;
    }

    & > div {
      font-size: 1.3em;
      font-weight: 600;
      height: 1.3em;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      & > * {
        color: #f2f2f2;
      }
    }
  }

  // For index grid
  &.cover-bloc-item--news--index {
    box-shadow: 2px 2px 11px rgba(0, 0, 0, 0.3);

    .cover-bloc-item__image {
      height: 15rem;
    }
    .cover-bloc-item__footer {
      height: 10rem;
    }
    .cover-bloc-item__info {
      h4,
      .h4 {
        height: 5.3rem;
        font-size: 1.4em;
      }
      & > div {
        font-size: 1.2em;
      }
    }
  }
}

.cover-bloc-item--news-a11y {
  overflow: visible;

  .ne-event-item__image {
    overflow: hidden;
  }

  &.cover-bloc-item--news--index {
    .cover-bloc-item__info {
      & > div {
        line-height: 1.33;
      }
    }
  }
}

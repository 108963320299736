.network__footer {
  background-color: $header_bg_color;
  color: lighten($header_font_color, 30%);
  display: hidden;

  a {
    color: lighten($header_font_color, 30%);

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  hr {
    border-bottom: 1px inset #fbfcfd;
    margin-bottom: 30px;
  }
}
.network__footer__hb-logo {
  max-height: 25px;
  margin-left: 5px;
}
.network-footer-first-line {
  margin-top: 56px;
  margin-bottom: 52px;
  margin-left: 32px;
  margin-right: 32px;
}

.network-footer__social-links {
  margin-top: 0px;

  @include media-query(palm) {
    margin-top: 48px;
  }

  @include media-query(lap) {
    margin-top: 52px;
  }
}
.network-footer__social-links__flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  @include media-query(palm) {
    justify-content: flex-start;
  }
  @include media-query(lap) {
    justify-content: flex-start;
  }
}

.network-footer__disclaimer {
  margin-left: 16px;
  margin-top: 52px;
  text-align: right;

  @include media-query(palm) {
    margin-left: 0;
    margin-top: 32px;
    text-align: left;
  }

  @include media-query(lap) {
    padding-top: 6px;
  }
}

.network-footer-column {
  margin-right: 24px;
}

.network-footer__section-title {
  color: darken($header_font_color, 10%);
  line-height: 1.3em;
  font-weight: 600;
  margin-bottom: 32px;

  @include media-query(palm) {
    margin-bottom: 16px;
  }
}
.network-footer__item {
  margin-bottom: 16px;
  font-size: 1.2307692308rem !important;

  @include media-query(palm) {
    font-size: 1.0769230769rem !important;
  }
}
.network-footer__social-icon {
  font-size: 1.5em;
  padding-left: 16px;
  padding-right: 0;
  padding-bottom: 16px;

  @include media-query(palm) {
    padding-left: 0;
    padding-right: 16px;
  }
  @include media-query(lap) {
    padding-left: 0;
    padding-right: 16px;
  }

  a {
    color: lighten($header_font_color, 30%);
    transition: opacity 0.2s;

    &:hover {
      color: lighten($header_font_color, 10%);
    }

    &:hover,
    &:focus {
      opacity: 0.7;
    }
  }

  &:last-child {
    padding-right: 0;
  }
}

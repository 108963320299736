@use "sass:math";

@import './_variables';
@import '../libs/inuit/tools.responsive';

/**
 * Add/remove floats
 */
.float--right   { float:right!important; }
.float--left    { float:left !important; }
.float--none    { float:none !important; }
.float--clear, .clearfix { // Put on parent Element
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}



$_breakpoints: (palm, lap, portable, lap-and-up, iphone-x, desk);
/**
 * Add/remove margins
 */
.push                  { margin:       $base-spacing-unit!important; }
.push--top             { margin-top:   $base-spacing-unit!important; }
.push--right           { margin-right: $base-spacing-unit!important; }
.push--bottom          { margin-bottom:$base-spacing-unit!important; }
.push--left            { margin-left:  $base-spacing-unit!important; }
.push--ends            { margin-top:   $base-spacing-unit!important; margin-bottom:$base-spacing-unit!important; }
.push--sides           { margin-right: $base-spacing-unit!important; margin-left:  $base-spacing-unit!important; }

.push-half             { margin:       $half-spacing-unit!important; }
.push-half--top        { margin-top:   $half-spacing-unit!important; }
.push-half--right      { margin-right: $half-spacing-unit!important; }
.push-half--bottom     { margin-bottom:$half-spacing-unit!important; }
.push-half--left       { margin-left:  $half-spacing-unit!important; }
.push-half--ends       { margin-top:   $half-spacing-unit!important; margin-bottom:$half-spacing-unit!important; }
.push-half--sides      { margin-right: $half-spacing-unit!important; margin-left:  $half-spacing-unit!important; }

.push-quarter          { margin:       $quarter-spacing-unit!important; }
.push-quarter--top     { margin-top:   $quarter-spacing-unit!important; }
.push-quarter--right   { margin-right: $quarter-spacing-unit!important; }
.push-quarter--bottom  { margin-bottom:$quarter-spacing-unit!important; }
.push-quarter--left    { margin-left:  $quarter-spacing-unit!important; }
.push-quarter--ends    { margin-top:   $quarter-spacing-unit!important; margin-bottom:$quarter-spacing-unit!important; }
.push-quarter--sides   { margin-right: $quarter-spacing-unit!important; margin-left:  $quarter-spacing-unit!important; }

.push-eighth          { margin:       math.div($quarter-spacing-unit, 2) !important; }
.push-eighth--top     { margin-top:   math.div($quarter-spacing-unit, 2) !important; }
.push-eighth--right   { margin-right: math.div($quarter-spacing-unit, 2) !important; }
.push-eighth--bottom  { margin-bottom:math.div($quarter-spacing-unit, 2) !important; }
.push-eighth--left    { margin-left:  math.div($quarter-spacing-unit, 2) !important; }
.push-eighth--ends    { margin-top:   math.div($quarter-spacing-unit, 2) !important; margin-bottom:math.div($quarter-spacing-unit, 2) !important; }
.push-eighth--sides   { margin-right: math.div($quarter-spacing-unit, 2) !important; margin-left:  math.div($quarter-spacing-unit, 2) !important; }

.push-double           { margin:       $base-spacing-unit * 2!important; }
.push-double--top      { margin-top:   $base-spacing-unit * 2!important; }
.push-double--right    { margin-right: $base-spacing-unit * 2!important; }
.push-double--bottom   { margin-bottom:$base-spacing-unit * 2!important; }
.push-double--left     { margin-left:  $base-spacing-unit * 2!important; }
.push-double--ends     { margin-top:   $base-spacing-unit * 2!important; margin-bottom:$base-spacing-unit * 2!important; }
.push-double--sides    { margin-right: $base-spacing-unit * 2!important; margin-left:  $base-spacing-unit * 2!important; }

.push-triple           { margin:       $base-spacing-unit * 3!important; }
.push-triple--top      { margin-top:   $base-spacing-unit * 3!important; }
.push-triple--right    { margin-right: $base-spacing-unit * 3!important; }
.push-triple--bottom   { margin-bottom:$base-spacing-unit * 3!important; }
.push-triple--left     { margin-left:  $base-spacing-unit * 3!important; }
.push-triple--ends     { margin-top:   $base-spacing-unit * 3!important; margin-bottom:$base-spacing-unit * 3!important; }
.push-triple--sides    { margin-right: $base-spacing-unit * 3!important; margin-left:  $base-spacing-unit * 3!important; }

.push-quadruple         { margin:       $base-spacing-unit * 4!important; }
.push-quadruple--top    { margin-top:   $base-spacing-unit * 4!important; }
.push-quadruple--right  { margin-right: $base-spacing-unit * 4!important; }
.push-quadruple--bottom { margin-bottom:$base-spacing-unit * 4!important; }
.push-quadruple--left   { margin-left:  $base-spacing-unit * 4!important; }
.push-quadruple--ends   { margin-top:   $base-spacing-unit * 4!important; margin-bottom:$base-spacing-unit * 4!important; }
.push-quadruple--sides  { margin-right: $base-spacing-unit * 4!important; margin-left:  $base-spacing-unit * 4!important; }

.flush                 { margin:       0!important; }
.flush--top            { margin-top:   0!important; }
.flush--right          { margin-right: 0!important; }
.flush--bottom         { margin-bottom:0!important; }
.flush--left           { margin-left:  0!important; }
.flush--ends           { margin-top:   0!important; margin-bottom:0!important; }
.flush--sides          { margin-right: 0!important; margin-left:  0!important; }
@each $state in $_breakpoints{
    @include media-query(#{$state}){
        .#{$state}--push-quadruple         { margin:       $base-spacing-unit * 4!important; }
        .#{$state}--push-quadruple--top    { margin-top:   $base-spacing-unit * 4!important; }
        .#{$state}--push-quadruple--right  { margin-right: $base-spacing-unit * 4!important; }
        .#{$state}--push-quadruple--bottom { margin-bottom:$base-spacing-unit * 4!important; }
        .#{$state}--push-quadruple--left   { margin-left:  $base-spacing-unit * 4!important; }
        .#{$state}--push-quadruple--ends   { margin-top:   $base-spacing-unit * 4!important; margin-bottom:$base-spacing-unit * 4!important; }
        .#{$state}--push-quadruple--sides  { margin-right: $base-spacing-unit * 4!important; margin-left:  $base-spacing-unit * 4!important; }

        .#{$state}--push-double            { margin:       $base-spacing-unit * 2!important; }
        .#{$state}--push-double--top       { margin-top:   $base-spacing-unit * 2!important; }
        .#{$state}--push-double--right     { margin-right: $base-spacing-unit * 2!important; }
        .#{$state}--push-double--bottom    { margin-bottom:$base-spacing-unit * 2!important; }
        .#{$state}--push-double--left      { margin-left:  $base-spacing-unit * 2!important; }
        .#{$state}--push-double--ends      { margin-top:   $base-spacing-unit * 2!important; margin-bottom:$base-spacing-unit * 2!important; }
        .#{$state}--push-double--sides     { margin-right: $base-spacing-unit * 2!important; margin-left:  $base-spacing-unit * 2!important; }

        .#{$state}--push                   { margin:       $base-spacing-unit!important; }
        .#{$state}--push--top              { margin-top:   $base-spacing-unit!important; }
        .#{$state}--push--right            { margin-right: $base-spacing-unit!important; }
        .#{$state}--push--bottom           { margin-bottom:$base-spacing-unit!important; }
        .#{$state}--push--left             { margin-left:  $base-spacing-unit!important; }
        .#{$state}--push--ends             { margin-top:   $base-spacing-unit!important; margin-bottom:$base-spacing-unit!important; }
        .#{$state}--push--sides            { margin-right: $base-spacing-unit!important; margin-left:  $base-spacing-unit!important; }

        .#{$state}--push-half              { margin:       $half-spacing-unit!important; }
        .#{$state}--push-half--top         { margin-top:   $half-spacing-unit!important; }
        .#{$state}--push-half--right       { margin-right: $half-spacing-unit!important; }
        .#{$state}--push-half--bottom      { margin-bottom:$half-spacing-unit!important; }
        .#{$state}--push-half--left        { margin-left:  $half-spacing-unit!important; }
        .#{$state}--push-half--ends        { margin-top:   $half-spacing-unit!important; margin-bottom:$half-spacing-unit!important; }
        .#{$state}--push-half--sides       { margin-right: $half-spacing-unit!important; margin-left:  $half-spacing-unit!important; }

        .#{$state}--push-quarter           { margin:       $quarter-spacing-unit!important; }
        .#{$state}--push-quarter--top      { margin-top:   $quarter-spacing-unit!important; }
        .#{$state}--push-quarter--right    { margin-right: $quarter-spacing-unit!important; }
        .#{$state}--push-quarter--bottom   { margin-bottom:$quarter-spacing-unit!important; }
        .#{$state}--push-quarter--left     { margin-left:  $quarter-spacing-unit!important; }
        .#{$state}--push-quarter--ends     { margin-top:   $quarter-spacing-unit!important; margin-bottom:$quarter-spacing-unit!important; }
        .#{$state}--push-quarter--sides    { margin-right: $quarter-spacing-unit!important; margin-left:  $quarter-spacing-unit!important; }

        .#{$state}--flush                  { margin:       0!important; }
        .#{$state}--flush--top             { margin-top:   0!important; }
        .#{$state}--flush--right           { margin-right: 0!important; }
        .#{$state}--flush--bottom          { margin-bottom:0!important; }
        .#{$state}--flush--left            { margin-left:  0!important; }
        .#{$state}--flush--ends            { margin-top:   0!important; margin-bottom:0!important; }
        .#{$state}--flush--sides           { margin-right: 0!important; margin-left:  0!important; }
    }
}



/**
 * Add/remove paddings
 */
.soft-quadruple            { padding:       $base-spacing-unit * 4!important; }
.soft-quadruple--top       { padding-top:   $base-spacing-unit * 4!important; }
.soft-quadruple--right     { padding-right: $base-spacing-unit * 4!important; }
.soft-quadruple--bottom    { padding-bottom:$base-spacing-unit * 4!important; }
.soft-quadruple--left      { padding-left:  $base-spacing-unit * 4!important; }
.soft-quadruple--ends      { padding-top:   $base-spacing-unit * 4!important; padding-bottom:$base-spacing-unit * 4!important; }
.soft-quadruple--sides     { padding-right: $base-spacing-unit * 4!important; padding-left:  $base-spacing-unit * 4!important; }

.soft-triple            { padding:       $base-spacing-unit * 3!important; }
.soft-triple--top       { padding-top:   $base-spacing-unit * 3!important; }
.soft-triple--right     { padding-right: $base-spacing-unit * 3!important; }
.soft-triple--bottom    { padding-bottom:$base-spacing-unit * 3!important; }
.soft-triple--left      { padding-left:  $base-spacing-unit * 3!important; }
.soft-triple--ends      { padding-top:   $base-spacing-unit * 3!important; padding-bottom:$base-spacing-unit * 3!important; }
.soft-triple--sides     { padding-right: $base-spacing-unit * 3!important; padding-left:  $base-spacing-unit * 3!important; }

.soft-double            { padding:       $base-spacing-unit * 2!important; }
.soft-double--top       { padding-top:   $base-spacing-unit * 2!important; }
.soft-double--right     { padding-right: $base-spacing-unit * 2!important; }
.soft-double--bottom    { padding-bottom:$base-spacing-unit * 2!important; }
.soft-double--left      { padding-left:  $base-spacing-unit * 2!important; }
.soft-double--ends      { padding-top:   $base-spacing-unit * 2!important; padding-bottom:$base-spacing-unit * 2!important; }
.soft-double--sides     { padding-right: $base-spacing-unit * 2!important; padding-left:  $base-spacing-unit * 2!important; }

.soft-double-half            { padding:       24px!important; }
.soft-double-half--top       { padding-top:   24px!important; }
.soft-double-half--right     { padding-right: 24px!important; }
.soft-double-half--bottom    { padding-bottom:24px!important; }
.soft-double-half--left      { padding-left:  24px!important; }
.soft-double-half--ends      { padding-top:   24px!important; padding-bottom:24px!important; }
.soft-double-half--sides     { padding-right: 24px!important; padding-left:  24px!important; }

.soft                   { padding:       $base-spacing-unit!important; }
.soft--top              { padding-top:   $base-spacing-unit!important; }
.soft--right            { padding-right: $base-spacing-unit!important; }
.soft--bottom           { padding-bottom:$base-spacing-unit!important; }
.soft--left             { padding-left:  $base-spacing-unit!important; }
.soft--ends             { padding-top:   $base-spacing-unit!important; padding-bottom:$base-spacing-unit!important; }
.soft--sides            { padding-right: $base-spacing-unit!important; padding-left:  $base-spacing-unit!important; }

.soft-half              { padding:       $half-spacing-unit!important; }
.soft-half--top         { padding-top:   $half-spacing-unit!important; }
.soft-half--right       { padding-right: $half-spacing-unit!important; }
.soft-half--bottom      { padding-bottom:$half-spacing-unit!important; }
.soft-half--left        { padding-left:  $half-spacing-unit!important; }
.soft-half--ends        { padding-top:   $half-spacing-unit!important; padding-bottom:$half-spacing-unit!important; }
.soft-half--sides       { padding-right: $half-spacing-unit!important; padding-left:  $half-spacing-unit!important; }

.soft-quarter           { padding:       math.div($half-spacing-unit, 2) !important; }
.soft-quarter--top      { padding-top:   math.div($half-spacing-unit, 2) !important; }
.soft-quarter--right    { padding-right: math.div($half-spacing-unit, 2) !important; }
.soft-quarter--bottom   { padding-bottom:math.div($half-spacing-unit, 2) !important; }
.soft-quarter--left     { padding-left:  math.div($half-spacing-unit, 2) !important; }
.soft-quarter--ends     { padding-top:   math.div($half-spacing-unit, 2) !important; padding-bottom:math.div($half-spacing-unit, 2) !important; }
.soft-quarter--sides    { padding-right: math.div($half-spacing-unit, 2) !important; padding-left:  math.div($half-spacing-unit, 2) !important; }

.soft-third           { padding:       math.div($half-spacing-unit, 3) !important; }
.soft-third--top      { padding-top:   math.div($half-spacing-unit, 3) !important; }
.soft-third--right    { padding-right: math.div($half-spacing-unit, 3) !important; }
.soft-third--bottom   { padding-bottom:math.div($half-spacing-unit, 3) !important; }
.soft-third--left     { padding-left:  math.div($half-spacing-unit, 3) !important; }
.soft-third--ends     { padding-top:   math.div($half-spacing-unit, 3) !important; padding-bottom:math.div($half-spacing-unit, 4) !important; }
.soft-third--sides    { padding-right: math.div($half-spacing-unit, 3) !important; padding-left:  math.div($half-spacing-unit, 4) !important; }

.soft-eighth           { padding:       math.div($half-spacing-unit, 4) !important; }
.soft-eighth--top      { padding-top:   math.div($half-spacing-unit, 4) !important; }
.soft-eighth--right    { padding-right: math.div($half-spacing-unit, 4) !important; }
.soft-eighth--bottom   { padding-bottom:math.div($half-spacing-unit, 4) !important; }
.soft-eighth--left     { padding-left:  math.div($half-spacing-unit, 4) !important; }
.soft-eighth--ends     { padding-top:   math.div($half-spacing-unit, 4) !important; padding-bottom:math.div($half-spacing-unit, 4) !important; }
.soft-eighth--sides    { padding-right: math.div($half-spacing-unit, 4) !important; padding-left:  math.div($half-spacing-unit, 4) !important; }

.hard                   { padding:       0!important; }
.hard--top              { padding-top:   0!important; }
.hard--right            { padding-right: 0!important; }
.hard--bottom           { padding-bottom:0!important; }
.hard--left             { padding-left:  0!important; }
.hard--ends             { padding-top:   0!important; padding-bottom:0!important; }
.hard--sides            { padding-right: 0!important; padding-left:  0!important; }
 @each $state in $_breakpoints{
    @include media-query(#{$state}){
        .#{$state}--soft-quadruple            { padding:       $base-spacing-unit * 4!important; }
        .#{$state}--soft-quadruple--top       { padding-top:   $base-spacing-unit * 4!important; }
        .#{$state}--soft-quadruple--right     { padding-right: $base-spacing-unit * 4!important; }
        .#{$state}--soft-quadruple--bottom    { padding-bottom:$base-spacing-unit * 4!important; }
        .#{$state}--soft-quadruple--left      { padding-left:  $base-spacing-unit * 4!important; }
        .#{$state}--soft-quadruple--ends      { padding-top:   $base-spacing-unit * 4!important; padding-bottom:$base-spacing-unit * 4!important; }
        .#{$state}--soft-quadruple--sides     { padding-right: $base-spacing-unit * 4!important; padding-left:  $base-spacing-unit * 4!important; }

        .#{$state}--soft-triple            { padding:       $base-spacing-unit * 3!important; }
        .#{$state}--soft-triple--top       { padding-top:   $base-spacing-unit * 3!important; }
        .#{$state}--soft-triple--right     { padding-right: $base-spacing-unit * 3!important; }
        .#{$state}--soft-triple--bottom    { padding-bottom:$base-spacing-unit * 3!important; }
        .#{$state}--soft-triple--left      { padding-left:  $base-spacing-unit * 3!important; }
        .#{$state}--soft-triple--ends      { padding-top:   $base-spacing-unit * 3!important; padding-bottom:$base-spacing-unit * 3!important; }
        .#{$state}--soft-triple--sides     { padding-right: $base-spacing-unit * 3!important; padding-left:  $base-spacing-unit * 3!important; }

        .#{$state}--soft-double            { padding:       $base-spacing-unit * 2!important; }
        .#{$state}--soft-double--top       { padding-top:   $base-spacing-unit * 2!important; }
        .#{$state}--soft-double--right     { padding-right: $base-spacing-unit * 2!important; }
        .#{$state}--soft-double--bottom    { padding-bottom:$base-spacing-unit * 2!important; }
        .#{$state}--soft-double--left      { padding-left:  $base-spacing-unit * 2!important; }
        .#{$state}--soft-double--ends      { padding-top:   $base-spacing-unit * 2!important; padding-bottom:$base-spacing-unit * 2!important; }
        .#{$state}--soft-double--sides     { padding-right: $base-spacing-unit * 2!important; padding-left:  $base-spacing-unit * 2!important; }

        .#{$state}--soft                   { padding:       $base-spacing-unit!important; }
        .#{$state}--soft--top              { padding-top:   $base-spacing-unit!important; }
        .#{$state}--soft--right            { padding-right: $base-spacing-unit!important; }
        .#{$state}--soft--bottom           { padding-bottom:$base-spacing-unit!important; }
        .#{$state}--soft--left             { padding-left:  $base-spacing-unit!important; }
        .#{$state}--soft--ends             { padding-top:   $base-spacing-unit!important; padding-bottom:$base-spacing-unit!important; }
        .#{$state}--soft--sides            { padding-right: $base-spacing-unit!important; padding-left:  $base-spacing-unit!important; }

        .#{$state}--soft-half              { padding:       $half-spacing-unit!important; }
        .#{$state}--soft-half--top         { padding-top:   $half-spacing-unit!important; }
        .#{$state}--soft-half--right       { padding-right: $half-spacing-unit!important; }
        .#{$state}--soft-half--bottom      { padding-bottom:$half-spacing-unit!important; }
        .#{$state}--soft-half--left        { padding-left:  $half-spacing-unit!important; }
        .#{$state}--soft-half--ends        { padding-top:   $half-spacing-unit!important; padding-bottom:$half-spacing-unit!important; }
        .#{$state}--soft-half--sides       { padding-right: $half-spacing-unit!important; padding-left:  $half-spacing-unit!important; }

        .#{$state}--soft-quarter           { padding:       math.div($half-spacing-unit, 2) !important; }
        .#{$state}--soft-quarter--top      { padding-top:   math.div($half-spacing-unit, 2) !important; }
        .#{$state}--soft-quarter--right    { padding-right: math.div($half-spacing-unit, 2) !important; }
        .#{$state}--soft-quarter--bottom   { padding-bottom:math.div($half-spacing-unit, 2) !important; }
        .#{$state}--soft-quarter--left     { padding-left:  math.div($half-spacing-unit, 2) !important; }
        .#{$state}--soft-quarter--ends     { padding-top:   math.div($half-spacing-unit, 2) !important; padding-bottom:math.div($half-spacing-unit, 2) !important; }
        .#{$state}--soft-quarter--sides    { padding-right: math.div($half-spacing-unit, 2) !important; padding-left:  math.div($half-spacing-unit, 2) !important; }

        .#{$state}--hard                   { padding:       0!important; }
        .#{$state}--hard--top              { padding-top:   0!important; }
        .#{$state}--hard--right            { padding-right: 0!important; }
        .#{$state}--hard--bottom           { padding-bottom:0!important; }
        .#{$state}--hard--left             { padding-left:  0!important; }
        .#{$state}--hard--ends             { padding-top:   0!important; padding-bottom:0!important; }
        .#{$state}--hard--sides            { padding-right: 0!important; padding-left:  0!important; }
    }
}


.soft-bottom--10 { padding-bottom: 10em; }
.soft-bottom--5 { padding-bottom: 5em; }


/*
 * Alignments
 */
.v-top            { vertical-align: top!important; }
.v-middle         { vertical-align: middle!important; }
.v-bottom         { vertical-align: bottom!important; }
.v-super          { vertical-align: super!important; }
.palm--v-middle   { @include media-query(palm) { vertical-align: middle!important; } }

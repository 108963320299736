//****
// NEWS ==> !!! USED BY Frontend && Public index views !!!
//****

// News header (search, total...)
.feed-index-listing__header__total {
  font-size: 1.4em;
}

// Topic item
.container--right .grid__item:last-of-type .feed-index-listing {
  border-bottom: 1px solid $light-gray;
}
.feed-index-listing {
  background-color: white;
  border-top      : 1px solid $light-gray;
  border-left     : 1px solid $light-gray;
  border-right    : 1px solid $light-gray;

  &:hover { background-color: rgba(255,255,255,.5); }
}

.feed-index-listing__description-container {
  @include media-query(lap-and-up) {
    height: 150px;
  }

  @include media-query(palm) {
    > div {
      text-overflow: ellipsis;
      width        : 100%;
      white-space  : nowrap;
      overflow     : hidden;
    }
  }
}
  .feed-index-listing__picture-container {
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: center center;
    height: 155px;

    @include media-query(ipad) { max-width: 100%!important; }
    @include media-query(palm) { margin-bottom: 10px; }
    @include media-query(lap-and-up) {
      max-height: 150px;
      max-width : 230px;
      width     : 100%;
    }
  }
  .feed-index-listing__name {
    white-space  : nowrap;
    overflow     : hidden;
    text-overflow: ellipsis;

    @include media-query(lap-and-up) {
      margin-right : 85px!important;
    }
  }
  .feed-index-listing__header {
    height         : 200px;
    background-size: cover;
  }
  .feed-index-listing__footer {
    @include media-query(palm) {
      margin-top: 10px;
    }
    @include media-query(lap-and-up) {
      position  : absolute;
      left      : 0;
      right     : 0;
      bottom    : 0;
    }
  }

.topic__select2-tags-form {
  .select2-container .select2-selection {
    min-height: 30px !important;
  }

  .select2-selection__choice {
    font-size: 0.9em !important;
    padding  : 0.3em 1.1em 0.3em 0.9em !important;

    .select2-selection__choice__remove {
      top: 3px !important;
    }
  }
}

// Sidebar blocs
.news__sidebar, .topics__sidebar {
  li, a {
    @include media-query(palm) {
      display      : inline-block!important;
      padding-right: 10px;
      font-size    : 1.1em;
    }
  }
}

@import 'legacy/stylesheets/helpers/_variables.scss';

.select2-dropdown {
  z-index: map-get($zIndices, "popover");
  border: 1px solid #e1e1e1 !important;
}

.select2-search__field {
  width: 100% !important;
  margin-top: 10px !important;
}

.select2-container {
  display: block;
  margin: 0 auto;

  .input & {
    width: 100% !important;
  }

  &.select2-container--open.select2-dropdown--above .select2-selection__choice {
    background-image: none !important;
    border: 1px solid $blue !important;
  }

  .select2-results__option[aria-selected='true'] {
    display: none !important;
  }

  .select2-selection {
    @extend .input-text !optional;
    min-height: 40px !important;
    padding: 0 !important;

    .select2-selection__choice {
      border: 0px;
      background-color: $blue;
      color: $white;
      padding: 0.5em 1.1em 0.5em 0.9em;
      box-shadow: none;
      background-image: none;
      margin-top: 6px;
      border-radius: 0.3em;
      font-size: 0.9em;
      word-break: normal;
      font-weight: 500;
      letter-spacing: 0.3px;
    }

    .select2-selection__choice__remove,
    .select2-selection__choice__remove:hover {
      background: transparent;
      background-image: none !important;
      color: white;
      font-size: 1.5em;
      line-height: 0;
      top: 2px;
      margin-right: 0.4em;
      position: relative;
      height: 1px;
      @include media-query(palm) {
        font-size: 1.7em;
      }
    }
  }
}

.select2-container-multi.select2-container-active .select2-choices {
  background-image: none !important;
  border: 1px solid $blue !important;
}

.select2-container-active .select2-choice {
  background-image: none !important;
  border: 1px solid $blue !important;
}

.select2-container--focus .select2-selection--multiple {
  border: 1px solid $blue !important;
}

.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background-color: $light-gray;
  color: inherit;
}

.select2--align-right {
  .select2-selection__choice {
    float: right !important;
    @include media-query(palm) {
      float: left !important;
    }
  }
}

.select2-selection__rendered {
  padding-right: 26px !important;
}

span.select2-selection__arrow,
span.select2-selection__rendered {
  line-height: 34px !important;
  height: 38px !important;
  right: 8px !important;

  b {
    border-width: 6px 6px 0 6px !important;
  }
}
.select2-container--open span.select2-selection__arrow b {
  border-width: 0 6px 6px 6px !important;
}
.select2-selection {
  overflow: auto !important;

  .select2-selection__rendered {
    overflow: hidden !important;

    .select2-search__field {
      //width: 100% !important;
    }
  }
}

.field_with_errors .select2-selection {
  border: 1px solid #FD4329;
}

.homepage-container {
  .select2-search--inline {
    width: 100%;
  }
}

// When there is no options shown, we want to see all the placeholder
.select2-search--inline {
  width: 100% !important;
}
// But when an option has been chosen, we don't show the placeolher anymore,
// so we don't need the width: 100% that breaks everything.
.select2-selection__choice + .select2-search--inline {
  width: auto !important;
}

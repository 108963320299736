input[disabled],
textarea[disabled] {
  background-color: #eee;
  color: #202020;
}
input,
textarea {
  cursor: text;
  padding: 0.5em;
  border: 1px solid #2b2b2b;
  max-width: 100%;
  resize: none;
  border-radius: 5px;
}

.input-with-icon {
  position: relative;
  input {
    padding-right: 20px;
    width: 100%;
  }
}
.input-with-icon--left {
  position: relative;
  input {
    padding-left: 20px;
    width: 100%;
  }
}

.inline-radio-form span {
  &,
  input,
  label {
    display: inline;
    vertical-align: middle;
  }
  label {
    margin-right: 1em;
  }
  input {
    margin-right: 0.5em;
    margin-bottom: 0;
  }
}
.inline-checkbox-form .boolean {
  input,
  label {
    display: inline;
    vertical-align: middle;
  }
  input {
    margin-right: 1em;
    margin-bottom: 0;
  }
}
.spoken-radio-form span {
  display: block;
  input,
  label {
    display: inline;
  }
  input {
    margin-right: 1em;
  }
}

.inverse-boolean-wrapper {
  input {
    margin: 0 !important;
  }
  label {
    margin-right: 1.5em !important;
    float: left !important;
  }
}

input[type='checkbox'].big {
  width: 25px;
  height: 25px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  color: #444;
  background: white;
  padding: 7px 5px 7px 8px;
  border: 1px solid #afafaf;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  border-radius: $brand-radius;
  outline: 0;
  text-align: left;
  margin: 1px;
  font-weight: 500;
  @media (-webkit-min-device-pixel-ratio: 0) {
    padding-right: 25px;
    //background-image    : url("icons/caret-down-with-right-padding.png");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: right center;
  }
  &:hover,
  &:focus {
    background-color: #eee;
  }
}

//------ Select2 inputs--------------------------

.input .select2-container .select2-choices,
.input .select2-container .select2-choice {
  cursor: text;
  border: 1px solid #ddd;
  max-width: 100%;
  resize: none;
  background: none;
  background-color: white;
  border-radius: $brand-radius;
  &:focus {
    border: 3px;
  }
}

div.select2-drop {
  border: 1px solid #ddd;
}

.select2-container ul.select2-choices {
  max-height: 200px;
  overflow-y: auto;
  background-image: none;
}

@use "sass:math";

@import 'legacy/stylesheets/helpers/_variables.scss';

abbr[title='required'] {
  border: none;
}

hr {
  border: none;
  border-bottom: 2px inset $site-background-color;
  &.dark {
    border-color: rgba(0, 0, 0, 0.2);
  }
}

.hr {
  clear: both;
  height: 1px;
  background-color: #c1d7e2;
  border-bottom: 2px solid $site-background-color;
}

.disclosed {
  input,
  label {
    display: inline;
    vertical-align: middle;
  }
}
.center-block {
  margin-left: auto;
  margin-right: auto;
}

.inline {
  display: inline;
}
.inline-wrapper {
  > h1,
  > h2,
  > h3,
  > h4,
  > nav,
  > label,
  > h2,
  > i,
  > p,
  > div,
  > ul,
  > span,
  > a,
  > img,
  > a,
  > input {
    display: inline;
  }
  &.align-middle {
    > h1,
    > h2,
    > h3,
    > h4,
    > nav,
    > label,
    > h2,
    > i,
    > p,
    > div,
    > ul,
    > span,
    > a,
    > img,
    > a,
    > input {
      vertical-align: middle;
    }
  }
}

.hidden {
  display: none;
}

.text--right {
  text-align: right;
}
.text--left {
  text-align: left;
}

.text--justified {
}

.v-middle {
  vertical-align: middle;
}
.v-top {
  vertical-align: top;
}
.v-bottom {
  vertical-align: bottom;
}
.float-right {
  float: right;
}
.float-left {
  float: left;
}

.white {
  color: white !important;
}
.gray {
  color: $gray !important;
}
.soft-gray {
  color: $soft-gray !important;
}
.dark-half-gray {
  color: $dark-half-gray !important;
}
.dark-gray {
  color: $dark-gray !important;
}
.red {
  color: red;
}
.soft-red {
  color: #e28f8f !important;
}
.green {
  color: green;
}
.soft-green {
  color: #71b671 !important;
}
.black {
  color: black;
}
.dark-blue {
  color: #31353e !important;
}
.blue {
  color: $super-blue;
}

.very-soft {
  padding: 0.5em !important;
}
.very-soft-margin {
  margin: 0.5em !important;
}
.very-soft--sides {
  padding: 0 0.5em !important;
}
.very-soft--top {
  padding-top: 0.5em;
}
.very-very-soft {
  padding: 0.3em;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.bold {
  font-weight: bold;
}
.semi-bold {
  font-weight: 500;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}

.or {
  margin: 0 0 2em 0;
  text-align: center;
  position: relative;
  .left,
  .right {
    border-bottom: 1px solid #555;
    position: absolute;
    width: 40%;
    bottom: 0;
  }
  .left {
    left: 0;
  }
  .right {
    right: 0;
  }
  span {
    color: #bbb;
    font-size: 14px;
    position: relative;
    bottom: -12px;
    padding: 0 1em;
  }
}

.white-bg {
  background-color: white;
}
.nowrap {
  white-space: nowrap;
}
.uppercase {
  text-transform: uppercase;
}

/*------------------------------------*\
    BACKGROUNDS
\*------------------------------------*/
.bg-cover {
  background-size: cover;
  background-position: center center;
}

// Usage:
// .black-curtain.north.west.one-whole.absolute
.black-curtain {
  height: 100%;
  transition: background 150ms linear;
  background: rgba(25, 29, 34, 0.45);
  &.black-curtain--dark {
    background: rgba(26, 30, 35, 0.75);
  }
  &.black-curtain--soft {
    background: rgba(0, 0, 0, 0.2);
  }
  &.black-curtain__fading-on-hover:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}

/*------------------------------------*\
    TEXTS
\*------------------------------------*/
.palm-text--center {
  @include media-query(palm) {
    text-align: center !important;
  }
}
.palm-hard {
  @include media-query(palm) {
    padding: 0 !important;
  }
}
.palm-flush {
  @include media-query(palm) {
    margin: 0 !important;
  }
}
.palm-push {
  @include media-query(palm) {
    margin: $base-spacing-unit !important;
  }
}
.palm-push--bottom {
  @include media-query(palm) {
    margin-bottom: $base-spacing-unit !important;
  }
}
.palm-push-double--bottom {
  @include media-query(palm) {
    margin-bottom: $base-spacing-unit * 2 !important;
  }
}
.palm-push--top {
  @include media-query(palm) {
    margin-top: $base-spacing-unit !important;
  }
}
.palm-push-half--bottom {
  @include media-query(palm) {
    margin-top: math.div($base-spacing-unit, 2) !important;
  }
}

.lap-text--center {
  @include media-query(lap) {
    text-align: center !important;
  }
}
.lap-hard {
  @include media-query(lap) {
    padding: 0 !important;
  }
}
.lap-flush {
  @include media-query(lap) {
    margin: 0 !important;
  }
}
.lap-push {
  @include media-query(lap) {
    margin: $base-spacing-unit !important;
  }
}
.lap-push--bottom {
  @include media-query(lap) {
    margin-bottom: $base-spacing-unit !important;
  }
}
.lap-push--top {
  @include media-query(lap) {
    margin-top: $base-spacing-unit !important;
  }
}
.lap-push-half--bottom {
  @include media-query(lap) {
    margin-top: math.div($base-spacing-unit, 2) !important;
  }
}

.invisible {
  opacity: 0;
}

.on-top {
  z-index: map-get($zIndices, "modal");
}

/**
 * Transition
 */
.transition-all-150 { transition: all 150ms cubic-bezier(0.190, 1.000, 0.220, 1.000); }
.transition-all-300 { transition: all 300ms cubic-bezier(0.190, 1.000, 0.220, 1.000); }
.transition-all-500 { transition: all 500ms cubic-bezier(0.190, 1.000, 0.220, 1.000); }
.transition-all-900 { transition: all 900ms cubic-bezier(0.190, 1.000, 0.220, 1.000); }

.no-animation {
  animation-duration: 0s !important;
}

.floating-animation {
  animation-name           : floating;
  animation-duration       : 1.5s;
  animation-iteration-count: infinite;
  animation-play-state     : running;
}

.fade-in-out {
  opacity: 0;
  animation-name           : fadeInAndOut;
  animation-duration       : 2s;
  animation-iteration-count: 1;
  animation-play-state     : running;
}

.fade-in--horizontal {
  animation-name: fadeInHorizontal;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.fade-out--horizontal {
  animation-name: fadeOutHorizontal;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.fade-in--vertical {
  animation-name: fadeInVertical;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.fade-out--vertical {
  animation-name: fadeOutVertical;
  animation-timing-function: ease-in;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
}

.rotate--90       { transform: rotate(90deg); }
.rotate--minus-90 { transform: rotate(-90deg); }
.rotate--minus-180 { transform: rotate(-180deg); }



.translate-x--right{
  @extend .transition-all-150;
  transform: translateX(0);
  &.translate {
    transform: translateX(105%); // 105 because it prevents box-shadow to be seen
  }
}

.spin-animation {
    animation-name: spin-keyframes;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin-keyframes {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
  }

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

@keyframes translate-from-bottom {
    from { transform: translateY(100%); }
    to   { transform: translateY(0); }
}

@keyframes translate-from-ttb {
    from { transform: translateY(-100%); }
    to   { transform: translateY(0); }
}

@keyframes floating {
  0%,100%{
    transform: translateY(0)
  } 50% {
    transform: translateY(-20%)
  }
}

@keyframes fadeInAndOut {
  0% { opacity: 0; }
  20%{ opacity: 1; }
  80% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes fadeInHorizontal {
  0% { opacity: 0; max-height: 0; max-width: 0; }
  30% { max-height: 0; max-width: 200px; }
  31% { opacity: 0; max-height: 200px; }
  100% { opacity: 1; max-height: auto; max-width: auto; }
}

@keyframes fadeOutHorizontal {
  0% { opacity: 1; }
  70% { opacity: 0; max-height: 200px; }
  71% { max-height: 0; max-width: 200px; }
  99% { max-width: 0; }
  100% { opacity: 0; max-height: 0; max-width: 0; }
}

@keyframes fadeInVertical {
  0% { opacity: 0; max-height: 0; }
  30% { max-height: 300px; }
  31% { opacity: 0; }
  100% { opacity: 1; max-height: auto; }
}

@keyframes fadeOutVertical {
  0% { opacity: 1; }
  70% { opacity: 0; }
  71% { max-height: 300px; }
  99% { max-height: 0; }
  100% { opacity: 0; max-height: 0; display: none !important; }
}

a[disabled] {
  pointer-events: none;
  cursor: default;
}
a {
  text-decoration: none;
  cursor: pointer;
  color: inherit;

  img {
    vertical-align: middle;
    max-width: 100%;
  }

  &:hover {
    text-decoration: underline;
  }
}

.cookies-eu {
  line-height: 54px;
  font-size: 13px;

  @include media-query(palm) {
    line-height: 34px;
    padding-left: 20px;
  }

  .cookies-eu-content-holder {
    line-height: 20px;
    padding-top: 20px;
  }

  .cookies-eu-button-holder {
    float: right;

    .cookies-eu-ok {
      text-transform: uppercase;
      font-size: 1.1em;
      padding: 8px 18px;
      border-radius: 3px;
      font-weight: 500;
      letter-spacing: 1px;
    }
  }
}

.cookies-eu-link {
  text-decoration: none;
  cursor: pointer;
  color: $gray;
}

.semi-muted-link {
  color: inherit;
  &:hover {
    text-decoration: underline;
  }
}

.muted-link {
  color: inherit;
  &:hover {
    text-decoration: none;
  }
}

.underline-on-hover {
  &:hover {
    text-decoration: underline;
  }
}

.action-link {
  text-decoration: underline;
  text-underline-position: under;
  text-decoration-thickness: 2px;
}

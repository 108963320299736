@import 'legacy/stylesheets/helpers/_variables.scss';

.modal-preview__overlay {
  &:not(.modal-preview__overlay--transparent-bg) {
    background-color: $transparent_background_modal;
  }
  z-index: -1;
  animation: fadin 1.5s cubic-bezier(0.19, 1, 0.22, 1);
  @extend .transition-all-300;
}

.modal-preview-view {
  outline: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 2em;
  bottom: 3em;
  z-index: map-get($zIndices, "modal");
  display: flex;

  @include media-query(portable) {
    top: $header-height;

    &.modal-preview-view--full {
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 !important;
    }
  }
}

// @TODO Hack to show content of double modal render
// ex: content display in preview modal (recent system), and another modal
// display with the old system (delete modal for exemple)
// Delete this hack when react modal system w'll be supported on all the app
.modal-preview-view ~ .reveal-modal {
  z-index: calc(map-get($zIndices, "modal") + 1) !important;
}
.modal-preview-view ~ .reveal-modal-bg {
  background: none;
}
// !End

.close-modal-preview {
  font-size: 1.6em;
  color: #fafafa;
  position: absolute;
  z-index: calc(map-get($zIndices, "modal") + 1);
  top: -8px;
  right: 15px;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  box-shadow: none;
  cursor: pointer;

  &:focus {
    outline: 1px dotted #212121;
    outline: 5px auto -webkit-focus-ring-color;
  }

  @include media-query(portable) {
    top: -18px;
  }
}

.modal-preview-view--closed {
  transform: translateY(100%);
}

.modal-preview__header {
  @extend .soft;
  @extend .bordered--bottom;

  h2 {
    font-weight: bold;
  }

  @include media-query(palm) {
    h2 {
      font-size: 1.2em;
    }
  }
}

.modal-preview__container {
  overflow-y: scroll;
  height: auto;
  max-height: 100%;
  overflow: auto;
  position: relative;
  width: 700px;
  max-width: 100%;
  margin: auto;
  animation: translate-from-bottom 0.7s cubic-bezier(0.19, 1, 0.22, 1);
  border-radius: 2px;
  overflow-x: hidden;
  box-shadow: 0px 2px 3px rgba(31, 31, 31, 0.3);
  background: $background-color;

  @extend .transition-all-300;
  @extend .relative;

  @include media-query(lap-and-up) {
    width: 900px;
  }

  @include media-query(palm) {
    width: 100%;
    max-width: 100%;
  }

  .feed-item__wrapper {
    margin-bottom: 0 !important;
    border: none !important;
  }
}

.modal-preview__container--bg-white {
  background-color: #fff;
}

.modal-preview__container--640px {
  max-width: 640px;
}

.modal-preview__container--small {
  max-width: 495px;
}
.modal-preview__container--medium {
  max-width: 790px;
}
.modal-preview__container--large {
  width: 1100px !important;
}
.modal-preview__container--targeting {
  height: 605px;
  overflow: hidden;
  & > .modal-preview__content {
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
    height: 100%;
    > .bg-white {
      -webkit-box-flex: 1 1 auto;
      -webkit-flex: 1 1 auto;
      flex: 1 1 auto;
      height: 0;
    }
  }
  @include media-query(palm) {
    border-radius: 0;
    height: 100%;
  }
}

.modal-preview__container--business_opportunities_request,
.modal-preview__container--event,
.modal-preview__container--ventures_venture {
  > .soft-double {
    padding: 0 !important;
  }
  border: none;
  height: 100%;
}

.modal-preview__container--ventures_venture,
.modal-preview__container--communications_post_communication {
  @include media-query(lap-and-up) {
    width: 900px;
  }
}

.modal-preview__container.modal-preview__container--media-center-file {
  width: 100%;
  background: transparent;
  border: none;
}

.bizopp-preview {
  .bizopp-preview__desc li {
    display: inline-block;
    border-left: 1px solid $border-soft-gray;
    padding: 0 20px;
    @include media-query(palm) {
      border-left: none;
      display: block;
    }

    &:first-of-type {
      color: $blue;
      border: none;
    }
  }

  .label {
    color: $text-dark-gray;
    padding-bottom: 5px;
  }

  .desc {
    color: $text-soft-gray;

    img {
      width: 80%;
    }
  }

  .social-header-view {
    padding-left: 0;
  }
}

.modal-preview__content--followers {
  max-height: 450px;

  @extend .grid;
  @extend .grid--full;
}

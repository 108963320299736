label {
  font-weight: 600;

  &.boolean,
  &.collection_radio_buttons {
    padding-left: 0.5em;
    vertical-align: middle;
  }
}
.required-label:after {
  content: ' *';
}

.input.boolean .hint {
  padding-left: 10px;
  color: $gray;
}

textarea {
  min-height: auto;
  overflow: hidden;
  &.four-lines {
    height: 65px;
  }
}
.textarea--fixed {
  resize: none;
}

textarea:focus,
input:focus {
  // To restore default focuse for inputs in any place in future. See: https://stackoverflow.com/questions/6601697/restore-webkits-css-outline-on-input-field
  outline-width: 0;
  border-color: inherit;
  box-shadow: none;
}

input[type='file'],
input[type='checkbox'],
input[type='radio'],
select {
  margin: 0px;
  cursor: pointer;
}

input[type='radio']:focus,
input[type='checkbox']:focus {
  outline-width: 5px;
}

select {
  width: 100%;
}

input-placeholder {
  color: inherit;
}

::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
input:-moz-placeholder {
  color: inherit;
  font-weight: inherit;
  font-style: italic;
  color: $gray;
}

input[type='text']:focus,
input[type='password']:focus,
input[type='date']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='month']:focus,
input[type='week']:focus,
input[type='email']:focus,
input[type='number']:focus,
input[type='search']:focus,
input[type='tel']:focus
  input[type='time']:focus
  input[type='url']:focus
  input[type='color']:focus
  textarea:focus
  select:focus {
  background: #ffffff;
  outline: none;
}

textarea {
  padding-left: 12px;
  padding-top: 12px;
}

.input-text,
input[type='text'],
input[type='tel'],
input[type='password'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='email'],
input[type='number'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
input[type='color'],
textarea {
  font-family: inherit;
  margin: 0;
  padding: 0.77em;
  width: 100%;
  border: 1px solid #2b2b2b;
  border-radius: 5px;
  background: #ffffff;
  font-size: 1em;
  font-weight: 400;
  color: #050505;
  box-shadow: none;
  -webkit-appearance: none;

  &:focus {
    border: 1px solid;
    border-color: #4d82ec;
  }

  &.input-error {
    background: rgba(254, 127, 127, 0.05);
    border: 1px solid $red;
  }

  &.input-error + .error-msg {
    text-align: left;
    color: $red;
  }

  &.input-error + .error-msg:before {
    @extend .font-wk !optional;
    content: '\e816';
    position: absolute;
    top: 10px;
    right: -20px;
    color: $red;
    font-size: 1.4em;
  }

  &[disabled] {
    background: #e3e4e4;
  }
}

.input {
  margin-bottom: 1em;
}

.input.boolean {
  white-space: nowrap;

  input {
    vertical-align: middle;
    margin-top: -0.15em;
  }
  label {
    display: inline-block;
    white-space: normal;
  }
  .error {
    display: block;
    white-space: normal;
  }
}


$error-color: #fd4329;
$error-exclamation: url("data:image/svg+xml;base64,PHN2ZyBhcmlhLWhpZGRlbj0idHJ1ZSIgZm9jdXNhYmxlPSJmYWxzZSIgZGF0YS1wcmVmaXg9ImZhcyIgZGF0YS1pY29uPSJjaXJjbGUtZXhjbGFtYXRpb24iIGNsYXNzPSJzdmctaW5saW5lLS1mYSBmYS1jaXJjbGUtZXhjbGFtYXRpb24gc2MtZ0V2RWVyIGtKRmhnbCIgcm9sZT0iaW1nIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIiBjb2xvcj0icmdiKDI1MywgNjcsIDQxKSIgc3R5bGU9Ii0tZmEtYW5pbWF0aW9uLWRlbGF5OiAwOyAtLWZhLWFuaW1hdGlvbi1kdXJhdGlvbjogMnM7IC0tZmEtYW5pbWF0aW9uLWl0ZXJhdGlvbi1jb3VudDogaW5maW5pdGU7IGhlaWdodDogMTZweDsiPjxwYXRoIGZpbGw9ImN1cnJlbnRDb2xvciIgZD0iTTI1NiA1MTJBMjU2IDI1NiAwIDEgMCAyNTYgMGEyNTYgMjU2IDAgMSAwIDAgNTEyem0wLTM4NGMxMy4zIDAgMjQgMTAuNyAyNCAyNFYyNjRjMCAxMy4zLTEwLjcgMjQtMjQgMjRzLTI0LTEwLjctMjQtMjRWMTUyYzAtMTMuMyAxMC43LTI0IDI0LTI0ek0yMjQgMzUyYTMyIDMyIDAgMSAxIDY0IDAgMzIgMzIgMCAxIDEgLTY0IDB6Ij48L3BhdGg+PC9zdmc+");
.error-with-exclamation-icon {
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    background: $error-exclamation;
    margin-right: 8px;
    margin-top: -0.15em;
  }
}
.field_with_errors {
  input,
  textarea,
  select {
    border: 1px solid $error-color;
  }
  .error {
    background-color: rgba($error-color, 0.1);
    font-size: 1.077rem;
    display: flex;
    margin-top: 8px;
    padding: 8px 12px;
    border-radius: 5px;
    align-items: center;

    i {
      margin-right: 5px;
    }

    @extend .error-with-exclamation-icon;
  }
  .error--inverted {
    color: white;
    background-color: $error-color;
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
  .error--flex {
    display: flex;
  }
  #consent__error {
    display: block;
  }
}

.form-errors__wrapper {
  background-color: rgba($error-color, 0.1);
  border-radius: 5px;
  padding: 12px;

  li {
    margin: 8px 0 8px 8px;
    line-height: 16px;

    @extend .error-with-exclamation-icon;
  }
}


.input-with-icon {
  position: relative;
  input {
    padding-right: 40px !important;
  }
  i {
    position: absolute;
    right: 10px;
    top: 50%;
    margin-top: -0.5em;
    line-height: 1;
    z-index: 1;
    color: $gray;
  }
}
.input-with-icon--left {
  input {
    padding-right: auto !important;
    padding-left: 40px !important;
  }
  i {
    left: 10px;
    right: auto;
  }
}

.datepicker--select-with-time,
.datepicker--select {
  white-space: nowrap;
  label {
    display: block;
  }
  select {
    width: 33%;
  }
}

.datepicker--select-with-time {
  select {
    width: 20%;
  }
}

.list-radio {
  .radio {
    display: block;
  }
}

.radio {
  margin-right: 2em;
}

select {
  width: 100%;
  padding: 0.5em;
  border: 1px solid #e1e1e1;
  border-radius: 2px;
  background: #ffffff;
  font-size: 1em;
  font-weight: 400;
  color: #2f363b;
  box-shadow: none;
  height: 36px;
}

// Algolia places input size
.ap-input {
  height: auto;
}

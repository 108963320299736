@import 'legacy/stylesheets/helpers/_variables.scss';

.ui-datepicker {
  min-width: 300px;
  background-color: $white;
  box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.25);
  padding: 20px 25px;
  z-index: map-get($zIndices, "popover") !important;
  display: none; // Hidden by default

  .ui-datepicker-prev,
  .ui-datepicker-next {
    @extend .transition-css !optional;
    font-size: 1.5em;

    &:hover {
      color: $blue;
      text-decoration: none;
    }
    & span {
      display: none;
    }
  }

  .ui-datepicker-prev {
    @extend .icon-chevron-left-circle;
    float: left;
    margin-top: 5px;
  }
  .ui-datepicker-next {
    @extend .icon-chevron-right-circle;
    float: right;
    margin-top: 5px;
  }

  .ui-datepicker-month,
  .ui-datepicker-year {
    width: 35%;
    display: inline-block !important;
    margin: 0 2px;
    padding: 5px 10px;
    height: 2.3em;
    font-size: 0.8em;
  }

  .ui-datepicker-title {
    @extend .h5;
    color: $blue;
    text-align: center;
    text-transform: uppercase;

    .ui-datepicker-year {
      display: block;
    }
  }

  .ui-datepicker-calendar {
    width: 100%;
    margin-top: 20px;

    thead {
      font-family: $header-font-bold;
    }

    tbody td {
      @extend .transition-css !optional;

      padding: 6px 4px;
      cursor: pointer;
      text-align: center;
    }
    tbody td:hover {
      background-color: $blue;

      a {
        color: $white;
      }
    }
    tbody td a {
      display: block;
      color: $gray;
      text-decoration: none;
    }

    .ui-datepicker-current-day {
      background: $yellow;
      a {
        color: white;
      }
    }
  }
  .ui-state-disabled {
    color: #ccc !important;
    cursor: default !important;

    &:hover {
      background-color: transparent !important;
    }
  }
}

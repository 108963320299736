.alert-box {
  padding: $half-spacing-unit;
  margin-bottom: $base-spacing-unit;
  border-radius: $brand-radius;
  line-height: 1.4em;
}

.alert-box--error {
  color: $red;
  border: 2px solid $red;
  background: $white;
}

.alert-box--tip {
  color: darken($yellow, 20%);
  border: 2px solid $yellow;
  background: $white;
}

.alert-box--blue {
  color: darken($blue, 20%);
  border: 2px solid $blue;
  background: $white;
}

.alert-box--info {
  color: darken($green, 20%);
  border: 2px solid $green;
  background: $white;
}

.alert-title--new-feature {
  color: $red;
  background: $white;
}

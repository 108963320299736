/*------------------------------------*\
    #SPACING-RESPONSIVE
\*------------------------------------*/

@mixin sronly {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.sr-only {
  @include sronly;
}

@mixin accessibility {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.accessibility,
.deprecated--visuallyhidden {
  @include accessibility;
}

/**
 * Simply hide content with `display:none;`, also provide breakpoint specific
 * hidden elements.
 */
@mixin hidden {
  display: none;
}

.hidden {
  @include hidden;
}

@each $state in palm, lap, lap-and-up, portable, desk, ipad {
  @include media-query(#{$state}) {
    .sr-only--#{$state} {
      @include sronly;
    }
    .accessibility--#{$state},
    .deprecated--visuallyhidden--#{$state} {
      @include accessibility;
    }
    .hidden--#{$state} {
      @include hidden;
    }
  }
}

@import 'legacy/stylesheets/helpers/_variables.scss';

.rslides {
  .homepage-slider-picture {
    height             : 700px;
    background-size    : cover;
    background-repeat  : no-repeat;
    background-position: center;
    z-index            : 0;
    &.full-width {
      li, img { height: 100vh; }
    }
  }

  .legend {
    animation           : legend-up 6580ms ease 0ms;
    animation-play-state: paused;
    display             : none;
    width               : 1170px;
    h2 {
      font-size    : 3em;
      background   : rgba(0, 0, 0, 0.3);
      padding      : 2px 6px;
      color        : #FFFFFF;
      display      : inline-block;
      margin-bottom: 10px;
    }
    p {
      font-size  : 1.3em;
      line-height: 1.8em;
      background : rgba(0, 0, 0, 0.3);
      padding    : 2px 6px;
      color      : #FFFFFF;
      display    : inline-block;
      max-width  : fit-content;
    }
  }

  .rslides1_on {
    .legend {
      display              : block;
      animation-play-state : running;
    }
  }
}

@keyframes legend-up {
  0%   {transform:translateY(160px); opacity: 0;}
  10%  {transform:translateY(160px); opacity: 0;}
  30%  {transform:translateY(0px);   opacity: 1;}
  93%  {transform:translateY(0px);   opacity: 1;}
  98%  {transform:translateY(0px);   opacity: 0;}
  100% {transform:translateY(0px);   opacity: 0;}
}

.rslides {
  position  : relative;
  list-style: none;
  width     : 100%;
  padding   : 0;
  &:not(.full-width) { max-width : 960px; }
  li {
    position: relative;
    overflow: hidden;

    .legend {
      position    : absolute;
      top         : 46%;
      margin      : 0;
      margin-left : auto;
      margin-right: auto;
      left        : 0;
      right       : 0;

      @include media-query(palm) {
        top       : 15% !important;
        left      : 10px !important;
        right     : 10px !important;
        max-width : 85vw !important;
        text-align: left !important;
      }

      @include media-query(lap) {
        top       : 30% !important;
        left      : 10px !important;
        right     : 10px !important;
        max-width : 85vw !important;
        text-align: left !important;
      }
    }
  }

  img {
    display: block;
    height : auto;
    width  : 100%;
    border : 0;
    width  : 100%;
    height : auto;
  }
}

.slide-pictures {
  img {
    height: 90px;
    width : 160px;
  }
}
.slide-pictures--picture {
  a {
    display    : none;
    height     : inherit;
    position   : absolute;
    left       : 0;
    right      : 0;
    top        : 0;
    bottom     : 0;
    background : rgba(255, 255, 255, 0.82);
    line-height: 90px;
    text-align : center;
    font-size  : 1.5em;
  }
  &:hover a { display: block; }
}

.rslides_tabs {
  display        : flex;
  justify-content: center;
  position       : absolute;
  margin-left    : auto;
  margin-right   : auto;
  left           : 0;
  right          : 0;
  margin-top     : -35px;
  z-index        : map-get($zIndices, "positioned");

  li a {
    display      : block;
    width        : 6px;
    height       : 6px;
    margin-right : 10px;
    border-radius: 50%;
    background   :  rgba(255, 255, 255, 0.8);
    text-indent  : -9999px;
    overflow     : hidden;
  }

  li:last-child a { margin-right: 0; }

  .rslides_here a { background: rgba(0, 0, 0, 0.8); }
}

.rslides_nav { display: none; }

.slider-indication {
  i { margin-top: 20px; }

  div { transform: translateY(20px); }
}


/*BO css*/
.backoffice-slider .rslides {
  .legend {
    width: 75%;

    h4 { font-size: 2em; }

    p { font-size: 1em; }
  }
}

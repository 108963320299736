// Network events
.events {
  padding-bottom: 15px;
  background-size: cover;
  background-position: top center;

  .btn {
    background-color: #2f363b;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 1em;
    padding: 10px 25px 10px 25px;
    border-radius: 3px;
    position: relative;
    font-weight: 600;
    letter-spacing: 1px;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

    &:hover {
      box-shadow: none;
      background: rgba(47, 54, 59, 0.8);

      &:before {
        transform: scaleX(1);
      }
    }

    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.15);
      transition: 0.2s transform ease-out;
      transform: scaleX(0);
      transform-origin: 100% 50%;
    }
  }

  h1 {
    color: white;
  }
  &.white--soft {
    h1,
    .btn {
      color: $dark-gray !important;
    }
    .btn {
      border: 1px solid #d8d8d8 !important;
    }
  }
}

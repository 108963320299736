.custom-error {
  z-index: 0;
  top: 0;
  right: 35px;
  height: 100%;
  opacity: 0;
  color: #be0101;
  display: flex;
  align-items: center;
  pointer-events: none;
}

.custom-error--anim {
  z-index: 2;
  opacity: 1;
  transition: all 0.5s;
}

.spinner-box {
  bottom: 10px;
  right: 35px;
}

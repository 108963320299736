.filter-item {
  cursor: pointer;
  padding: 0.2em 1em;
  line-height: 1.8em;
  border: 0;
  border-radius: 4px;
  background: transparent;
  margin-right: 0.5em;
  display: inline-flex;

  &.filter-item--active a {
    color: white;
  }

  @extend .transition-all-150;

  &:hover {
    background: transparentize($blue, 0.9s);
    text-decoration: none;
  }

  &--inverted {
    border: 1px solid $blue;
  }
}

.filter-item--active,
input[type='radio']:checked + label.filter-item {
  &,
  &:hover {
    background: $blue;
    color: white !important;
  }
}

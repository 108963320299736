.button {
  background-color: #ddd;
  border: 1px solid transparent;
  border-radius: 2px;
  color: #fff;
  cursor: pointer;
  text-align: center;
  padding: 1em;
  display: inline-block;
  font-size: $base-font-size-rem;
  line-height: 1.2;
  vertical-align: middle;
  border: none;
  -webkit-appearance: none;

  @extend .transition-all-300;

  &:hover,
  &:focus {
    opacity: 1;
    text-decoration: none;
  }

  &[disabled] {
    background: $btn--disabled !important;
    cursor: auto;
  }

  .icon-trash {
    padding-left: 5px;

    &:hover {
      color: red;
    }
  }

  &.button--full {
    width: 100%;
  }
}

.button--cancel {
  background: #f1f1f4;
  color: black;
  border: 1px solid darken(#f1f1f4, 5%);

  &:hover,
  &:focus {
    background: darken(#f1f1f4, 4%);
  }

  &[disabled] {
    background: darken(#f1f1f4, 10%) !important;
    border-color: darken(#f1f1f4, 10%);
  }
}

.btn--extra-light {
  padding: 10px 30px;
  border: 1px solid #d8d8d8;
  color: #464646;
  font-weight: 600;
  font-size: 1.4em;
  border-radius: 2px;
  &:hover {
    background: $light-gray;
    color: $dark-gray !important;

    @extend .transition-all-300;
  }
}
.btn__connect {
  background: rgba(255, 255, 255, 0.15);
  color: white;
  text-transform: uppercase;
  font-size: 13px;
  padding: 17px 25px 15px 25px;
  border-radius: 3px;
  font-weight: 600;
  letter-spacing: 1px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

  &:hover {
    box-shadow: none;
    background: rgba(255, 255, 255, 0.2);

    &:before {
      transform: scaleX(1);
    }
  }

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.15);
    transition: 0.2s transform ease-out;
    transform: scaleX(0);
    transform-origin: 100% 50%;
  }
}

.button__validation {
  color: #4e4e4e;
  background: transparent;
  border: 1px solid #b5b5b5;
  font-weight: 600;
  text-transform: uppercase;

  &:hover {
    @extend .transition-all-150;
    background-color: #4e4e4e;
    color: white;
    border-color: #4e4e4e;
  }
}
.button__validation--red {
  background-color: #eb5c4a;
  border: none;
  color: white;

  &:hover {
    background-color: #e8432e;
  }
}
.button__validation--blue {
  background-color: #4285f4;
  border: none;
  color: white;

  &:hover {
    background-color: #2d79f7;
  }
}

.button-group {
  $border-radius: 8px;

  display: inline-block;

  .button {
    font-weight: bold;
  }

  .button:first-child {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
  }

  .button:last-child {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .button:not(:last-child) {
    border-right: none;
  }
}

.button-group--full {
  display: table;
  width: 100%;

  .button {
    display: table-cell;
    vertical-align: middle;
  }
}

.button.rounded--circle {
  // Defining minimal width as button height using formula:
  // line-height + padding-top + padding-bottom
  min-width: $base-font-size * 1.2 + $base-font-size * 2;
}

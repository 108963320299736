/**
 * Borders
 */
.bordered--top    { border-top    : 1px solid $light-gray; }
.bordered--bottom { border-bottom : 1px solid $light-gray; }
.bordered--left   { border-left   : 1px solid $light-gray; }
.bordered--right  { border-right  : 1px solid $light-gray; }
.bordered--ends   { @extend .bordered--top; @extend .bordered--bottom; }
.bordered--sides  { @extend .bordered--left; @extend .bordered--right; }
.bordered         { border        : 1px solid $light-gray; }
.bordered--none   { border: none!important; }
.bordered-none--top { border-top: none !important; }

.palm--bordered--bottom   { @include media-query(palm) { border-bottom : 1px solid $light-gray; } }
.palm--border-none        { @include media-query(palm) { border: none; } }
.palm--border-sides--none { @include media-query(palm) { border-left: none; border-right: none; } }


/**
 * Border width
 */
.border-width--2  { border-width: 2px; }

/**
 * Border colors
 */
.border-color--transparent  { border-color: rgba(255, 255, 255, 0.3); }
.border-color--dark         { border-color: #555; }
.border-color--darkgray     { border-color: #C6C6C6; }
.border-color--mediumgray   { border-color: #CECECE; }
.border-color--gray         { border-color: #f2f2f2; }
.border-color--blue         { border-color: $blue !important; }
.border-color--red          { border-color: $red !important; }
.border-color--very-softgray  { border-color: #F3F3F3 !important }
.border-color--white        { border-color: white; }


hr {
  border       : none;
  border-bottom: 1px inset $light-gray;
  margin-top   : $base-spacing-unit;
  margin-bottom: $base-spacing-unit;
}

/**
 * Shadow
 */
.shadowed--top              { box-shadow: 1px -1px 6px #CCC; }
.shadowed--left             { box-shadow: -2px 0px 6px rgba(221, 221, 221, 0.58); }
.shadowed--bottom           { box-shadow: 1px 1px 6px #CCC; }
.shadowed--bottom-dark      { box-shadow: 0px 2px 2px rgba(0, 27, 72, 0.3) }

.users-listing {
  @include media-query(palm) { position: static; }
  @include media-query(lap) { position: static; }

  color: $gray;
}
  .users-listing__user {
    &.user-listing__user-overlap li a {
      margin-left: -10px!important;
      display    : inline-table;
    }
    li {
      display     : inline-block;
      margin-right: 5px;

      img {
        border-radius: 50%;
        width        : 20px;
        height       : 20px;
        &.big {
          width : 28px;
          height: 28px;
        }
      }
    }
  }
  .users-listing__total {
    padding       : 3px 0 3px 5px;
    vertical-align: middle;
    font-weight   : 600;

    i {
      font-size  : 1.1em;
      font-weight: 800;
    }
  }

.input-blue-bg {
  border: 1px solid $blue;
  background: rgba(77, 129, 236, 0.05);
}

.input-transparent-bg {
  appearance: none;
  outline: 0;
  padding: 1.9em 1em 0.5em 1em;
  outline: 0;
  text-align: left;
  color: #444;
  font-weight: 300;
  min-height: 4em;
  border: 1px solid transparent;
  &:focus {
    @extend .input-blue-bg;
  }
}

.search-bar-input {
  position: relative;
}
.search-bar-input__input {
}

.search-bar-input__input--input {
  width: 100%;
  border: none !important;
  @extend .input-transparent-bg;
}

.search-bar-input__input--select {
  @extend .input-transparent-bg;
}

.search-bar-input--industries,
.search-bar-input--kinds {
  .select2-selection__choice {
    font-size: 0.8em;
  }
  .select2-container--open .select2-selection,
  .select2-container--focus .select2-selection {
    @extend .input-blue-bg;
  }
  .select2-selection {
    border: 1px solid transparent;
  }
  .select2-container .select2-selection {
    padding: 1.6em 1em 0.2em 10px !important;
    .select2-search__field {
      @include media-query(palm) {
        padding-left: 0.4em;
      }
      margin-top: 0.3em !important;
      &::-webkit-input-placeholder,
      &:-moz-placeholder,
      &::-moz-placeholder,
      &:-ms-input-placeholder {
        color: black !important;
      }
    }
  }
  .select2-selection__rendered {
    padding-left: 0px !important;
    input {
      -webkit-appearance: none !important;
    }
    .select2-search__field {
      width: 100% !important;
    }
  }
}

.search-bar-input__input--select2 {
  // Select 2 overrides...
  .select2-choices {
    box-shadow: none !important;
    padding: 2.3em 1em 0em 0.8em;
    border: none;
  }

  // Select 2 overrides
  .select2-default {
    font-size: 12px !important;
    height: 2em !important;
    padding-top: 0 !important;
    height: auto !important;
  }

  .select2-search-choice {
    margin-top: 0 !important;
    padding: 0.4em 1em 0.4em 2em !important;
    font-size: 0.9em !important;
    .select2-search-choice-close {
      top: 4px !important;
    }
  }
}

// Typeahead
.search-bar-input {
  .select2-selection__arrow {
    display: none;
  }
  .typeahead--active .search-bar-input__input {
    @extend .input-blue-bg;
  }
  ::-webkit-input-placeholder {
    color: #444 !important;
    font-weight: 300;
  }
  :-moz-placeholder {
    color: #444 !important;
    font-weight: 300;
  }
  ::-moz-placeholder {
    color: #444 !important;
    font-weight: 300;
  }
  :-ms-input-placeholder {
    color: #444 !important;
    font-weight: 300;
  }
  .tt-input,
  .tt-hint,
  .ap-input {
    border: 1px solid transparent !important;
    padding: 1.9em 1em 0.5em 10px;
    height: auto;
  }
}

.search-bar-input__input:focus + .search-bar-input__label,
.select2-container-active ~ .search-bar-input__label,
.typeahead--active ~ .search-bar-input__label {
  color: $blue;
}

.search-bar-input__label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 10px;
  display: block;
  color: $blue;
  font-weight: 500;
  font-size: 1em;
}

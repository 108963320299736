.container--centered {
  max-width   : 1190px;
  height      : 100%;
  margin-left : auto;
  margin-right: auto;
}

.container--large {
  max-width: 1150px;
}

.container--medium {
  max-width: 1000px;
}

.container--small {
  max-width: 850px;
}

.container--extra-small {
  max-width: 700px;
}

.container--tiny {
  max-width: 500px;
}

.container--micro {
  max-width: 300px;
}

.container--form {
  max-width: 800px;
}

.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
}

.flex--horizontal {
  -webkit-flex-direction: row;
  flex-direction: row;
}

.flex--vertical {
  -webkit-flex-direction: column;
  flex-direction: column;
}

.flex--justify-center {
  -webkit-justify-content: center;
  justify-content: center;
}

.flex--justify-end {
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.space-between {
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.space-around {
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.space-evenly {
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
}

.flex-center {
  -webkit-align-items: center;
  align-items: center;
}

.flex-end {
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.flex-grow--1 {
  flex-grow: 1;
}

.flex-basis--0 {
  flex-basis: 0;
}

.flex--1 {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}
.palm--flex-wrap {
  @include media-query(palm) {
    flex-wrap: wrap;
  }
}

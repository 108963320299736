.bloc-item-container {
  padding-left: 0;
  .cover-bloc-item--event {
    box-shadow: none;
  }
}

.cover-bloc-item--event {
  background: #fff;
  border-radius: 2px;
  color: #404d57;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: transform 0.24s, box-shadow 0.24s, -webkit-transform 0.24s;
  box-shadow: 2px 4px 18px rgba(0, 0, 0, 0.1);

  a {
    text-decoration: none;
  }

  &:hover {
    .cover-bloc-item__image {
      transform: scale(1.04);

      &::after {
        opacity: 0.2;
      }
    }

    .cover-bloc-item__footer {
      transform: translateY(-37px);
    }
  }

  .cover-bloc-item__image {
    border-radius: 2px 2px 0 0;
    display: block;
    height: 15.3rem;
    overflow: hidden;
    width: 100%;
    transition: transform 0.9s;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1));
      z-index: 1;
      content: ' ';
      -webkit-transition: opacity 0.5s cubic-bezier(0.9, 0.26, 0.58, 1) false;
      -moz-transition: opacity 0.5s cubic-bezier(0.9, 0.26, 0.58, 1) false false;
      -o-transition: opacity 0.5s cubic-bezier(0.9, 0.26, 0.58, 1) false false;
      transition: opacity 0.5s cubic-bezier(0.9, 0.26, 0.58, 1);
    }
  }

  .cover-bloc-item__header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 37px;
    padding: 0 5px;
  }

  .cover-bloc-item__footer {
    display: flex;
    flex-flow: column;
    height: 10.2rem;
    position: relative;
    background-color: #ffffff;
    transition: transform 0.5s cubic-bezier(0.9, 0.26, 0.58, 1);
    transition-delay: 0.1s;
  }

  .cover-bloc-item__info {
    flex: 1 1 auto;
    color: #6a6b71;
    padding: 1.2rem 1.4rem;
    border-bottom: none;
    text-align: left;

    h4 {
      opacity: 0;
      transition: opacity 0.1s cubic-bezier(0.9, 0.26, 0.58, 1);
      height: 54px;
      word-break: normal;
      font-size: 1.4rem;
      font-weight: 600;
      margin-bottom: 4px;
    }

    & > div {
      font-size: 1.1rem;
    }
  }
  .cover-bloc-item__tags {
    max-height: 37px;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    padding: 0.8rem 1.9rem;
    color: #6a6b71;
    border-top: 1px solid #eeeeee;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    a {
      color: #6a6b71;
      font-size: 1.1rem;
    }
  }
}

.twitter-typeahead {
  width: 100%;
  .tt-query, .tt-hint {
    margin-bottom: 0;
    width        : 100%;
  }
}

.tt-dropdown-menu {
  min-width              : 220px;
  margin-top             : 2px;
  padding                : 5px 0;
  background-color       : #fff;
  border                 : 1px solid #ccc;
  border                 : 1px solid rgba(0,0,0,.2);
  *border-right-width    : 2px;
  *border-bottom-width   : 2px;
  border-radius          : 6px;
  box-shadow             : 0 5px 10px rgba(0,0,0,.2);
  -webkit-background-clip: padding-box;
  -moz-background-clip   : padding;
  background-clip        : padding-box;
  > div:first-child {
    border-top : none;
    margin-top : 0;
    padding-top: 0;
  }
  > div {
    border-top : 1px solid #CCC;
    margin-top : 0.5em;
    padding-top: 0.5em;
  }
}

.tt-suggestion {
  display: block;
  padding: 3px 20px;
  color  : #222;
  p {
    margin-bottom: 0;
  }
}

.tt-suggestion.tt-is-under-cursor {
  color            : #fff;
  background-color : #0081c2;
  background-image : -moz-linear-gradient(top, #0088cc, #0077b3);
  background-image : -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0077b3));
  background-image : -webkit-linear-gradient(top, #0088cc, #0077b3);
  background-image : -o-linear-gradient(top, #0088cc, #0077b3);
  background-image : linear-gradient(to bottom, #0088cc, #0077b3);
  background-repeat: repeat-x;
  filter           : progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0077b3', GradientType=0)
}

.tt-suggestion.tt-is-under-cursor a {
  color: #fff;
}

.tt-suggestion p {
  margin: 0;
}

.user-search-dropdown .tt-suggestion div {
  font-size: 1em;
}

/**
 * Labels
 */
label {
  font-weight: 500;
  cursor: pointer;
}

.label--block {
  display: block;
  padding-top: $quarter-spacing-unit;
  padding-bottom: $quarter-spacing-unit;
  word-break: break-word;
}

.hidden-checkbox-with-icon-label-next-to-it {
  display: none;
  & + label {
    color: $gray;
  }
  &:checked + label {
    color: $blue;
  }
}

@include media-query(palm) {
  label {
    display: block;
    padding-bottom: 0.5em;
  }
}

input[type='text'],
textarea {
  -webkit-appearance: none;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

select {
  height: 3em;
}

.camera-upload-label {
  cursor: pointer;
  background-color: transparent;
  display: inline-block;
  text-align: left;

  &,
  i:before,
  span {
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  i {
    position: relative;
    top: 2px;
    color: white;
  }
  span {
    opacity: 0;
    line-height: normal;
  }

  .block {
    padding: 0.5em 0.4em;

    &:first-child {
      border-bottom: none !important;
    }
    &:last-child {
      border-top: none !important;
      background-color: $red;
    }
  }

  &:hover,
  &:focus,
  &:focus-within {
    background-color: rgba(0, 0, 0, 0.8);

    .block {
      border: 1px solid rgba(255, 255, 255, 0.3);
      &:last-child {
        display: block;
      }
    }

    span {
      opacity: 1;
      color: white;
    }

    i:before {
      transform: scale(0.75);
      opacity: 0.9;
    }
  }
}

.saving-notification {
  color: $green;
  opacity: 0;
  font-weight: 500;
  font-size: 1.2em;
  @extend .transition-all-300;
  &.saving-notification--visible {
    opacity: 1;
  }
}

@import 'legacy/stylesheets/helpers/_variables.scss';

.skip-to-content {
  position: absolute;
  border: 0;
  cursor: pointer;
  top: 0;
  left: 8px;
  opacity: 0;
  overflow: hidden;
  transform: translateY(-40px);
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  white-space: nowrap;
  user-select: none;
  z-index: map-get($zIndices, "offcanvas") !important;

  &:focus {
    opacity: 1;
    transform: translateY(0);
  }

  &--wrapper {
    color: #555;
    font-size: 1.07rem;
    font-weight: 600;
    line-height: 1.3rem;
    display: flex;
    padding: 8px;
    background-color: #fff;
  }
}

@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter/Inter-regular.eot');
  src: local('Inter Regular'), local('Inter-Regular'),
    url('../../fonts/Inter/Inter-regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Inter/Inter-regular.woff') format('woff'),
    url('../../fonts/Inter/Inter-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter/Inter-semi-bold.eot');
  src: local('Inter SemiBold'), local('Inter-semi-bold'),
    url('../../fonts/Inter/Inter-semi-bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Inter/Inter-semi-bold.woff') format('woff'),
    url('../../fonts/Inter/Inter-semi-bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

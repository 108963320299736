@font-face {
  font-family: 'Nunito';
  src: url('../../fonts/Nunito/Nunito-regular.eot');
  src: local('Nunito Regular'), local('Nunito-Regular'),
    url('../../fonts/Nunito/Nunito-regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Nunito/Nunito-regular.woff') format('woff'),
    url('../../fonts/Nunito/Nunito-regular.ttf') format('truetype'),
    url('../../fonts/Nunito/Nunito-regular.svg#Roboto') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('../../fonts/Nunito/Nunito-semi-bold.eot');
  src: local('Nunito SemiBold'), local('Nunito-semi-bold'),
    url('../../fonts/Nunito/Nunito-semi-bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/Nunito/Nunito-semi-bold.woff') format('woff'),
    url('../../fonts/Nunito/Nunito-semi-bold.ttf') format('truetype'),
    url('../../fonts/Nunito/Nunito-semi-bold.svg#Roboto') format('svg');
  font-weight: 600;
  font-style: normal;
}

@import 'legacy/stylesheets/helpers/_variables.scss';

.ui-helper-hidden-accessible {
  display: none;
}
.ui-autocomplete {
  z-index: map-get($zIndices, "popover");
  background: $white;

  .ui-menu-item {
    padding: 0;
    &.ui-state-focus {
      background: $yellow;
    }
    a {
      padding: 10px 12px;
    }
  }
}

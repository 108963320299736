.or-separator{
  display         : table;
  text-align      : center;
  width           : 100%;
  padding         : 20px 0;
  margin          : 0 auto;
  border-top-color: #333;
  border-radius   : 8px;

  &:before, &:after{
    content          : '';
    display          : table-cell;
    background       : #AFAFAF;
    width            : 50%;
    -webkit-transform: scaleY(0.1);
    transform        : scaleY(0.1);
  }
  > span{
    white-space: pre;
    padding    : 0 15px;
    text-transform: uppercase;
  }
}

.white-box-with-shadow {
  @extend .white-box;
  border: none !important;
}
.white-box {
  background-color: white;
  margin-bottom   : 1em;
  border-radius   : $brand-radius;
}

.white-box-with-header { @extend .white-box; }
  .white-box__footer--absolute {
    bottom  : 0;
    width   : 100%;
    position: absolute;
    @extend .white-box__footer;
  }
  .white-box__footer--relative {
    bottom  : 0;
    width   : 100%;
    position: relative;
    @extend .white-box__footer;
  }
  .white-box__footer {
    background-color: $very-light-gray;
    color           : #777;
    padding         : 0.3em 0.8em;
    border-top      : 1px solid #F1F1F1;
  }

.gray-box {
  background-color: $very-light-gray;
  color           : #666;
  padding         : 0.3em 0.5em;
  margin-bottom   : 1em;
  border-radius   : $brand-radius;
}
.gray-box-with-header { @extend .gray-box; }

.hp-white-box {
  color           : rgb(50, 58, 69);
  background-color: rgba(255, 255, 255, 0.9);

  &:not(.full) { border-radius: $brand-radius;}
  a:not(.btn) { color: rgb(50, 58, 69); }
  h3 { font-weight: 200; }
}

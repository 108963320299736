.user-item__img {
  border-radius: 3px;
}

.user-item__img--rounded {
  border-radius: 5em;
}
.user-item__img--rounded-50 {
  border-radius: 50%;
}

.user-item__img--100 {
  height: 100px;
  width : 100px;
}

.user-item__img--80 {
  height: 80px;
  width : 80px;
}

.user-item__img--70 {
  height: 70px;
  width : 70px;
}

.user-item__img--60 {
  height: 60px;
  width : 60px;
}

.user-item__img--50 {
  height: 50px;
  width : 50px;
}

.user-item__img--45 {
  height: 45px;
  width : 45px;
}
.palm--user-item__img--45 {
  @include media-query(palm) {
    height: 45px;
    width : 45px;
  }
}

.user-item__img--48 {
  height: 48px;
  width : 48px;
}

.user-item__img--40 {
  height: 40px;
  width : 40px;
}

.user-item__img--32 {
  height: 32px;
  width : 32px;
}

.user-item__img--28 {
  height: 28px;
  width : 28px;
}

.user-item__img--mini {
  height: 32px;
  width : 32px;
}

.user-search-dropdown {
  min-width: 30em !important;
}

.user-picture__name {
  word-break: break-word;
}

.scroll-desactivated {
  overflow: hidden !important;

  @include media-query(portable) {
    position: fixed !important;
    width: 100%; // Position fixed intronduces some unstable behaviour on element sizing if applied to the <body/>. This keeps the consitency.
  }
}

/*
* Adds offset to anchors to prevent them from being hidden behind the fixed header
* This targets all elements that can be linked to with #fragment-identifiers
*/
:target {
  scroll-margin-top: calc(#{$header-height} + 20px);
}

/* Fallback for older browsers or for when scroll-margin-top is not supported */
html {
  scroll-padding-top: calc(#{$header-height} + 20px);
}

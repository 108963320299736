@use "sass:math";

/**
 * Font weights
 */
.weight--light {
  font-weight: 300 !important;
  strong {
    font-weight: 400 !important;
  }
}
.weight--normal {
  font-weight: 400 !important;
}
.weight--semibold {
  font-weight: 500 !important;
}
.weight--bold {
  font-weight: 600 !important;
}
.weight--heavy {
  font-weight: 900 !important;
}

/**
 * Font family
 */
.font--primary {
  font-family: $font-primary;
}
.font--secondary {
  font-family: $header-font;
}

/**
 * Font sizes
 */
.f-size--small {
  font-size: $base-font-size - 1 !important;
}
.f-size--0-8 {
  font-size: $base-font-size - 2 !important;
}
.f-size--0-9 {
  font-size: $base-font-size - 1 !important;
}
.f-size--1 {
  font-size: $base-font-size !important;
}
.f-size--1-1 {
  font-size: $base-font-size + 1 !important;
}
.f-size--1-2 {
  font-size: $base-font-size + 2 !important;
}
.f-size--1-3 {
  font-size: $base-font-size + 3 !important;
}
.f-size--1-4 {
  font-size: $base-font-size + 4 !important;
}
.f-size--1-5 {
  font-size: $base-font-size + 5 !important;
}
.f-size--1-6 {
  font-size: $base-font-size + 6 !important;
}
.f-size--1-7 {
  font-size: $base-font-size + 7 !important;
}
.f-size--2-0 {
  font-size: $base-font-size + 10 !important;
}
.f-size--verysmall {
  font-size: $base-font-size - 3 !important;
}
.f-size--veryverysmall {
  font-size: $base-font-size - 5 !important;
}
.line-height-2 {
  line-height: 2em !important;
}
.line-height-1-5 {
  line-height: 1.5em !important;
}
.line-height-1-4 {
  line-height: 1.4em !important;
}
.line-height-1-3 {
  line-height: 1.3em !important;
}
.line-height-normal {
  line-height: normal !important;
}
.font-in-rem {
  .f-size--small {
    font-size: $base-font-size-rem - math.div(1,13) !important;
  }
  .f-size--0-8 {
    font-size: $base-font-size-rem - math.div(2,13) !important;
  }
  .f-size--0-9 {
    font-size: $base-font-size-rem - math.div(1,13) !important;
  }
  .f-size--1 {
    font-size: $base-font-size-rem !important;
  }
  .f-size--1-1 {
    font-size: $base-font-size-rem + math.div(1,13) !important;
  }
  .f-size--1-2 {
    font-size: $base-font-size-rem + math.div(2,13) !important;
  }
  .f-size--1-3 {
    font-size: $base-font-size-rem + math.div(3,13) !important;
  }
  .f-size--1-4 {
    font-size: $base-font-size-rem + math.div(4,13) !important;
  }
  .f-size--1-5 {
    font-size: $base-font-size-rem + math.div(5,13) !important;
  }
  .f-size--1-6 {
    font-size: $base-font-size-rem + math.div(6,13) !important;
  }
  .f-size--1-7 {
    font-size: $base-font-size-rem + math.div(7,13) !important;
  }
  .f-size--2-0 {
    font-size: $base-font-size-rem + math.div(10,13) !important;
  }
  .f-size--verysmall {
    font-size: $base-font-size-rem - math.div(3,13) !important;
  }
  .f-size--veryverysmall {
    font-size: $base-font-size-rem - math.div(5,13) !important;
  }
  .line-height-2 {
    line-height: 2rem !important;
  }
  .line-height-1-5 {
    line-height: 1.5rem !important;
  }
  .line-height-1-4 {
    line-height: 1.4rem !important;
  }
  .line-height-1-3 {
    line-height: 1.3rem !important;
  }
  .line-height-1-2 {
    line-height: 1.2rem !important;
  }
  .line-height-1-1 {
    line-height: 1.1rem !important;
  }
  .line-height-normal {
    line-height: normal !important;
  }
}

/**
 * Alignments
 */
.text--center {
  text-align: center !important;
}
.text--left {
  text-align: left !important;
}
.text--right {
  text-align: right !important;
}
.palm-text--center {
  @include media-query(palm) {
    text-align: center !important;
  }
}
.palm-text--left {
  @include media-query(palm) {
    text-align: left !important;
  }
}
.palm-text--right {
  @include media-query(palm) {
    text-align: right !important;
  }
}
.nowrap {
  white-space: nowrap;
}
.white-space--normal {
  white-space: normal;
}
.white-space--pre {
  white-space: pre;
}
.break-all {
  word-break: break-all;
}
.break-word {
  word-break: break-word;
  overflow-wrap: break-word;
}
.word-break-normal {
  word-break: normal;
}

.text-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/**
 * Font color
 */
.color-black {
  color: black !important;
}
.color-dark-gray {
  color: $dark-gray !important;
}
.color-dark-half-gray {
  color: $dark-half-gray !important;
}
.color-gray {
  color: $gray !important;
}
.color-gray-smooth {
  color: $smooth-gray !important;
}
.color-gray-light {
  color: $light-gray !important;
}
.color-gray-soft {
  color: $soft-gray !important;
}
.color-white {
  color: $white !important;
}
.color-yellow {
  color: $yellow !important;
}
.color-green {
  color: $green !important;
}
.color-blue {
  color: $blue !important;
}
.color-orange {
  color: $orange !important;
}
.color-red {
  color: $red !important;
}
.color-red-soft {
  color: #ff5a5f !important;
}
.color-twitter-blue {
  color: $twitter_blue !important;
}

/**
 * Text transformation
 */
.text-transform-lowercase {
  text-transform: lowercase;
}
.text-transform-uppercase {
  text-transform: uppercase;
}
.text-transform-capitalize {
  text-transform: capitalize;
}
.text-shadowed {
  text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.6);
}
.text-shadowed--soft {
  text-shadow: 0px 1px 0px rgba(45, 45, 45, 0.51);
}
.text-shadowed--verysoft {
  text-shadow: 0px 0px 32px rgba(0, 0, 0, 0.2);
}
.text-decoration--underlined {
  text-decoration: underline;
  text-underline-position: under;
}
.text-decoration--none {
  text-decoration: none;
}

/**
 * Font styles
 */
.f-style--italic {
  font-style: italic;
}

/**
 * Letter spacing
 */
.letter-spacing--0-7 {
  letter-spacing: -0.3px;
}
.letter-spacing--1 {
  letter-spacing: 0px;
}
.letter-spacing--1-3 {
  letter-spacing: 0.3px;
}
.letter-spacing--1-4 {
  letter-spacing: 0.4px;
}
.letter-spacing--1-5 {
  letter-spacing: 0.5px;
}
.letter-spacing--1-6 {
  letter-spacing: 0.6px;
}

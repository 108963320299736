/**
 * Backgrounds
 */
.bg-white {
  background-color: $white;
}
.bg-black {
  background-color: #333;
}
.bg-gray {
  background-color: #f1f1f4;
}
.bg-blue {
  background-color: $blue;
}
.bg-blue-dark {
  background: $background-color--blue;
}
.bg-gray-light {
  background-color: #fafafa;
}
.bg-green {
  background-color: $green;
}
.bg-red {
  background-color: $red;
}
.bg-yellow {
  background-color: #fec736;
}
.bg-black-faded {
  background-color: black;
  background-color: rgba(0, 0, 0, 0.65);
}
.bg-white-faded {
  background-color: black;
  background-color: rgba(255, 255, 255, 0.8);
}
.bg--dark-blue {
  background-color: $background-color--blue;
}
.bg-facebook {
  background-color: $facebook-color;
}
.bg-linkedin {
  background-color: $linkedin-color;
}
.bg-google {
  background-color: $google-plus-color;
}
.bg-apple {
  background-color: $black;
}
.bg-xing {
  background-color: $xing-color;
}

.white-box {
  @extend .bg-white;
  @extend .bordered;
  @extend .border-color--gray;
}

/**
 * Background images
 */
.bg-fixed {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

// Usage:
// Add the following inside a div that has a background-image
// .black-curtain.north.west.one-whole.absolute
.black-curtain {
  background: rgba(0, 0, 0, 0.5);
  height: 100%;
}
.black-curtain--soft {
  background: rgba(0, 0, 0, 0.15);
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -350px 0;
  }
  100% {
    background-position: 0 0;
  }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  @extend .transition-all-300;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-repeat: no-repeat;
  background-size: 1200px 104px;
  position: relative;
}

.membership--checked {
  bottom: 0;
  top: 0;
  left: 0;
  position: absolute;
  right: 0;
  background: rgba(255, 255, 255, 0.82);

  i {
    float: right;
    margin-right: 10px;
  }
}

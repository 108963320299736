.cover-bloc-item {
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.1);
  color: #404d57;
  cursor: pointer;
  position: relative;
  transition: box-shadow 0.24s, -webkit-transform 0.24s;
  transition: transform 0.24s, box-shadow 0.24s;
  transition: transform 0.24s, box-shadow 0.24s, -webkit-transform 0.24s;

  a {
    text-decoration: none;
  }

  &:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.1);

    &:not(.cover-bloc-item--fix) {
      transform: translateY(-2px);
    }

    .black-curtain {
      @extend .transition-all-900;
      opacity: 0.6;
    }
    .cover-bloc-item__corner-tag span {
      @extend .transition-all-900;
      background: rgba(247, 247, 247, 0.9);
    }
  }

  .cover-bloc-item__image {
    background-repeat: no-repeat;
    border-radius: 2px 2px 0 0;
    display: block;
    height: 13.75rem;
    overflow: hidden;
    width: 100%;

    div:not(.cover-bloc-item__followers-listing) {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
  .cover-bloc-item__image--title {
    font-size: 1.7em;
  }
  .cover-bloc-item__followers-listing {
    display: none;
    position: absolute;
    right: 0;
    bottom: 10px;
    color: white;
  }

  .cover-bloc-item__header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 37px;
    padding: 5px;
  }
  .cover-bloc-item__corner-tag {
    background: rgba(247, 247, 247, 0.8);
    padding: 5px 15px;
    color: #4e4e4e;
    border-radius: 2px;
    line-height: 18px;

    &:hover {
      background: white !important;
    }
  }

  .cover-bloc-item__footer {
    display: flex;
    flex-flow: column;
    height: 14.5625rem;
    padding: 1.725rem 1.75rem;
    padding-bottom: 1.125rem;
    position: relative;
  }
  .cover-bloc-item__footer--small {
    height: auto;
  }
  .cover-bloc-item__info {
    flex: 1 1 auto;
    height: 15px;

    h4 {
      max-height: 50px;
      word-break: normal;
    }
  }
  .cover-bloc-item__tags {
    max-height: 50px;
    a {
      margin-bottom: 5px;
      padding: 2px 7px;
      color: #676767;
      background: rgb(206, 206, 206);
      border-radius: 3px;
      word-break: break-word;
      display: -webkit-inline-box;
    }
  }
}

// Found at: http://tobiasahlin.com/spinkit/
/* Usage:
      <div class="loader">
          <div class="double-bounce1"></div>
          <div class="double-bounce2"></div>
      </div>
 */
.loader {
  width: 50px;
  height: 50px;

  position: relative;
  margin: 0 auto;
  &.loader--half {
    width: 20px;
    height: 20px;
  }
}

.loader__fill-parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;

  .loader {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
}

.double-bounce1 {
  background-color: $blue;
}
.double-bounce2 {
  background-color: #ccc;
}
.double-bounce3 {
  background-color: $red;
}

.double-bounce1,
.double-bounce2,
.double-bounce3 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.8;
  position: absolute;
  top: 0;
  left: 0;
  animation: sk-bounce 3s infinite ease-in-out;
}

// .double-bounce2 {
//     animation-delay: -1.5s;
// }

// @keyframes sk-bounce {
//     0%, 66%, 100% {
//         transform: scale(0.0);
//     } 33% {
//         transform: scale(1.0);
//     }
// }
.double-bounce2 {
  animation-delay: -1s;
}
.double-bounce3 {
  animation-delay: -2s;
}

@keyframes sk-bounce {
  0%,
  66%,
  100% {
    transform: scale(0);
  }
  33% {
    transform: scale(1);
  }
}

.page-loader {
  background: var(--color-background);
  transition: opacity 350ms ease-in-out;
}

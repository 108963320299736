.tag-m {
  font-weight: 400;
  font-style: normal;
  color: rgba(0, 0, 0, 0.44);
  font-size: 1rem;
  letter-spacing: 0;
  text-decoration: none;
  background: #fafafa;
  border-radius: 3px;
  border: 1px solid #f0f0f0;
  padding: 5px 10px;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(255, 255, 255, 0.97);
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 8px;
  max-width: 95%;
  position: relative;
  text-overflow: ellipsis;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;

  &.selected {
    @extend .weight--bold;
  }
  &:hover {
    text-decoration: none;
  }
  &:hover:not(.tag--disabled) {
    color: rgba(0, 0, 0, 0.8);
    border-color: rgba(0, 0, 0, 0.15);
    background: #fff;
  }
  &.tag-m--wrap {
    white-space: inherit;
  }
  &-accessible {
    color: #757575;
  }
}
// Colors
.tag-m--blue {
  background: $blue;
  color: white;
}

.tag--green {
  background-color: $green;
  color: white;
  border: none !important;
}

.tag-m--gray {
  background: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.6);
  border-color: rgba(165, 165, 165, 0.45);

  &:hover:not(.tag--disabled) {
    color: rgba(0, 0, 0, 0.6);
    background: rgba(0, 0, 0, 0.1);
  }
}
// Styles
.tag-m--medium {
  @extend .soft-quarter--ends;
}
.tag-m--inline {
  @extend .flush--bottom;
}

.tag {
  background: $blue;
  padding: 0.4em 1em;
  margin: 0.3em 0.2em 0.3em 0;
  display: inline-block;
  color: white;
  //max-width     : 18em;
  border-radius: 0.3em;
  font-size: 0.9em;
  word-break: normal;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.tag--unlimited {
  max-width: none;
}

.tag--outlined-gray {
  border: 1px solid #ddd;
  background: white;
}

.tag--disabled {
  background: #ddd;
  color: #666;
  cursor: default !important;
}

.tag--transparent {
  background: transparent;
}

.tag--narrow {
  padding: 0.2em 1em;
}

.tag--break-all {
  word-break: break-all;
}

.attachments-container {
  a,
  i {
    color: $gray;
  }
  .icon-paperclip {
    font-size: 1em !important;
  }
  i:not(.icon-multiply) {
    font-size: 1.5em;
    vertical-align: middle;
  }
}

.tag__emailing--sent {
  background-color: #e3eaef;
  color: #313a46;
  border: none !important;
}

.tag__emailing--delivered {
  background-color: #e3eaef;
  color: #313a46;
  border: none !important;
}

.tag__emailing--open {
  background-color: #e3eaef;
  color: #313a46;
  border: none !important;
}

.tag__emailing--click {
  background-color: #e3eaef;
  color: #313a46;
  border: none !important;
}

.tag__emailing--dropped {
  background-color: #e3eaef;
  color: #313a46;
  border: none !important;
}

.tag__emailing--bounce {
  background-color: #e3eaef;
  color: #313a46;
  border: none !important;
}

.tag__emailing--spamreport {
  background-color: #e3eaef;
  color: #313a46;
  border: none !important;
}

.click-counter__wrapper {
  border-bottom: 1px solid rgb(255, 0, 0);
}

.click-counter__tag {
  background: rgb(255, 0, 0);
  color: white;
  border-radius: 50%;
  padding: 0px 4px;
  font-size: $base-font-size;
}

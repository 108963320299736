@import 'legacy/stylesheets/helpers/_variables.scss';

// The dropdown wrapper (div)
.dropup, .dropdown { position: relative; }

// Prevent the focus on the dropdown toggle when closing dropdowns
.dropdown-toggle:focus { outline: 0; }

// Algolia and basic dropdown
.ap-dropdown-menu.ap-with-places, .dropdown-menu {
  z-index: map-get($zIndices, "popover") !important;
}
// The dropdown menu (ul)
.dropdown-menu {
  max-height      : 500px;
  overflow-y      : auto;
  position        : absolute;
  top             : 100%;
  left            : 0;
  z-index         : map-get($zIndices, "popover") !important;
  display         : none;
  float           : left;
  min-width       : 120px;
  margin          : 0;
  list-style      : none;
  background-color: white;
  border-radius   : 0 0 3px 3px;
  box-shadow      : 0 1px 2px rgba(0,0,0,.25), 0 0 1px rgba(0,0,0,.35);
  background-clip : padding-box;
  color: #050505;

  // Dropdown must be on hight z-index position
  &--on-top {
    z-index: calc(map-get($zIndices, "popover") + 1) !important;
  }

  // Aligns the dropdown menu to right
  //
  // Deprecated as of 3.1.0 in favor of `.dropdown-menu-[dir]`
  &.pull-right {
    right: 0;
    left : auto;
  }

  // Dividers (basically an hr) within the dropdown
  .divider {
    font-size     : .75em;
    color         : $gray;
    padding       : 10px 20px 0 20px;
    text-align    : left;
    border-top    : 1px solid $smooth-gray;
    text-transform: capitalize;
    font-size     : 1.1em;
  }

   // Links within the dropdown menu
   li a,
   li button
   {
    cursor     : pointer;
    color      : $black;
    padding    : 10px 20px;
    font-size  : 1em;
    line-height: 100%;
    text-align : left;
    display    : block;
    clear      : both;
    font-weight: normal;
    white-space: nowrap; // prevent links from randomly breaking onto new lines
    margin     : 0;
    background : transparent;
    border-radius: 0;

    > i {
      padding-right: 0.5em;
    }
  }
}

.dropdown-menu--full {
  left: 0;
  right: 0;
}
.dropdown-menu--right {
  left : auto;
  right: 0;
}
.dropdown-menu--left {
  left : 0;
  right: auto;
}
.palm--dropdown-menu--left {
  @include media-query(palm) {
    left : 0;
    right: auto;
  }
}

// Hover/Focus state
.dropdown-menu > li a,
.dropdown-menu > li button,
.dropdown-menu .dropdown__item {
  &:hover,
  &:focus {
    text-decoration : none;
  }
}

// Active state
.dropdown-menu > .active > a
.dropdown-menu > .active button
{
  &,
  &:hover,
  &:focus {
    color           : red;
    text-decoration : none;
    outline         : 0;
    background-color: blue;
  }
}

// Disabled state
//
// Gray out text and ensure the hover/focus state remains gray

.dropdown-menu > .disabled > a
.dropdown-menu > .disabled button
{
  &,
  &:hover,
  &:focus {
    //color: @dropdown-link-disabled-color;
  }

  // Nuke hover/focus effects
  &:hover,
  &:focus {
    text-decoration : none;
    background-color: transparent;
    background-image: none; // Remove CSS gradient
    //cursor: @cursor-disabled;
  }
}

// Open state for the dropdown
.open {
  // Show the menu
  > .dropdown-menu { display: block; }
  // Remove the outline when :focus is triggered
}

.dropup,
.navbar-fixed-bottom .dropdown {
  // Reverse the caret
  .caret {
    border-top: 0;
    content: "";
  }
  // Different positioning for bottom up menu
  .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-bottom: 2px;
  }
}

.dropdown-menu .tt-selectable:hover,
.dropdown-menu .tt-selected,
.dropdown li:hover  {
  cursor: pointer;
  background-color: $light-gray;
}

.dropdown li:hover a,
.dropdown li:hover a:before
.dropdown li:hover button,
.dropdown li:hover button:before
{
  color: #FFFFFF !important;
}

// Font size
.dropdown-menu--f-size--1-3 {
  font-size: 1.13em !important;
}
.portable--dropdown-menu--f-size--1-3 {
  @include media-query(portable) {
    font-size: 1.13em !important;
  }
}

@import 'legacy/stylesheets/helpers/_variables.scss';

/*
* Display
*/

$_breakpoints: (palm, lap, portable, lap-and-up);

.inline-block {
  display: inline-block;
}
.inline-block-important {
  display: inline-block !important;
}
.inline {
  display: inline;
}
.block {
  display: block;
}
.hidden {
  display: none;
} // /!\ DO NOT SET IMPORTANT otherwise js.show() won't work.
.invisible {
  visibility: hidden;
} // /!\ DO NOT SET IMPORTANT otherwise js.show() won't work.
.opacity-90 {
  opacity: 0.9;
}
.opacity-80 {
  opacity: 0.8;
}
// Responsive
.palm--block {
  @include media-query(palm) {
    display: block !important;
  }
}
.palm--hidden {
  @include media-query(palm) {
    display: none !important;
  }
}
.lap--block {
  @include media-query(lap) {
    display: block !important;
  }
}
.lap-block {
  @include media-query(lap) {
    display: block !important;
  }
}
.clear-both {
  clear: both;
}

/*
* Positioning
*/
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.absolute-important {
  position: absolute !important;
}
.center--absolute {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.center-vertical--absolute {
  top: 50%;
  transform: translateY(-50%);
}
.center-horizontal--absolute {
  left: 50%;
  transform: translateX(-50%);
}
.palm--static {
  @include media-query(palm) {
    position: static !important;
  }
}
.palm--absolute {
  @include media-query(palm) {
    position: absolute !important;
  }
}
.fixed {
  position: fixed;
}
.z-index--1 {
  z-index: 1;
}
.z-index--2 {
  z-index: 2;
}
.z-index--500 {
  z-index: 500;
}
.on-top {
  z-index: map-get($zIndices, "modal");
}
.on-top--more {
  z-index: calc(map-get($zIndices, "modal") + 1);
}
.on-top--verymore {
  z-index: calc(map-get($zIndices, "modal") + 2);
}
.center-block {
  margin-left: auto;
  margin-right: auto;
}
.west {
  left: 0 !important;
}
.east {
  right: 0 !important;
}
.north {
  top: 0 !important;
}
.south {
  bottom: 0 !important;
}
.palm--float--right {
  @include media-query(palm) {
    float: right;
  }
}

.bg-cover {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;

  &.editable {
    cursor: pointer;
  }
  > i {
    font-size: 8em;
    color: white;
  }
  &.bg-cover__with-upload-logo:hover:before {
    font-family: 'KitIconFont';
    content: '\f158';
    font-size: 60px;
    display: table-cell;
    vertical-align: middle;
    color: white;
    background: rgba(0, 0, 0, 0.3);
  }
}
.bg-center {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.height-100-percent {
  height: 100%;
}
.min-height-100-vh {
  min-height: 100vh;
}
// Firefox hack, where min-height is not interpret on display: table;
@-moz-document url-prefix() {
  .flexbox.min-height-100-vh {
    height: 100vh;
  }
}
.min-height-75-vh {
  min-height: 75vh;
}
.min-height-50-vh {
  min-height: 50vh;
}
.min-height-40-vh {
  min-height: 40vh;
}
.min-height-35-vh {
  min-height: 35vh;
}
.min-height-25-vh {
  min-height: 25vh;
}
.min-height-20-vh {
  min-height: 20vh;
}
.min-height--200 {
  min-height: 200px;
}
.min-height--60 {
  min-height: 60px;
}
.height-100-vh {
  height: 100vh;
}
.height--200 {
  height: 200px;
}
.--height--18-vh {
  height: 18vh !important;
  @media (max-width: 1440px) {
    height: 24vh !important;
  }
}
.height--40 {
  height: 40px;
}

.min-width--fit-content {
  min-width: fit-content;
}
.width--100-percent {
  width: 100%;
}
.width--90 {
  width: 90px;
}
.width--150 {
  width: 150px;
}
.width--250 {
  width: 250px;
}
.width--400 {
  width: 400px;
}
.width--600 {
  width: 600px;
}
.width--700 {
  width: 700px;
}
.width--900 {
  width: 900px;
}
.width--fit-content {
  width: fit-content;
}

@each $state in $_breakpoints {
  @include media-query(#{$state}) {
    .#{$state}--min-height--60 {
      min-height: 60px;
    }
    .#{$state}--width--100-percent {
      width: 100%;
    }
  }
}

/*
* Radius
*/
.squared {
  border-radius: 0 !important;
}
.rounded--circle {
  border-radius: 100% !important;
}
.rounded {
  border-radius: $brand-radius;
}
.rounded--top {
  border-top-left-radius: $brand-radius;
  border-top-right-radius: $brand-radius;
}
.rounded--right {
  border-top-right-radius: $brand-radius;
  border-bottom-right-radius: $brand-radius;
}
.rounded--left {
  border-top-left-radius: $brand-radius;
  border-bottom-left-radius: $brand-radius;
}

/*
* Overflow
*/
.overflow--auto {
  overflow: auto;
}
.overflow--scroll {
  overflow: scroll;
}
.overflow--hidden {
  overflow: hidden;
}

.overflow-x--auto {
  overflow-x: auto;
}
.overflow-x--scroll {
  overflow-x: scroll;
}
.overflow-x--hidden {
  overflow-x: hidden;
}

.overflow-y--auto {
  overflow-y: auto;
}
.overflow-y--scroll {
  overflow-y: scroll;
}
.overflow-y--hidden {
  overflow-y: hidden;
}

/*
* Cursor
*/
.cursor-pointer {
  cursor: pointer;
}
.cursor-text {
  cursor: text !important;
}
.cursor-default {
  cursor: default !important;
}

.fade-on-hover {
  @extend .transition-all-150;
  &:hover {
    opacity: 0.7;
  }
}

.opacity-on-hover__wrapper {
  .opacity-on-hover__child {
    opacity: 0;
    @extend .transition-all-300;
  }
  &:hover .opacity-on-hover__child {
    opacity: 1;
  }
}

/*
* Resize
*/
.resize-v {
  resize: vertical;
}
.resize-h {
  resize: horizontal;
}

/*
* Filter
*/
.black-and-white {
  filter: grayscale(1);
}

/*
* Image
*/
.img-fit--cover {
  object-fit: cover;
}
.img-fit--contain {
  object-fit: contain;
}

/*
* Touch Actions
*/
.touch-pan--y {
  touch-action: pan-y;
}

.ckeditor-content--reset {
  word-break: break-word;

  hr {
    border-color: #ccc;
  }

  ul,
  ol {
    list-style: disc;
    padding: 0 40px;
    margin: 13px 0;
  }
  ol {
    list-style-type: decimal;
  }
  ul {
    list-style-type: disc;
  }

  a:not(.button) {
    color: $blue;
  }

  figure {
    text-align: center;
    padding: 10px;
    margin: 10px 20px;
    display: inline-block;
  }
  figcaption {
    padding-top: 5px;
    text-align: center;
  }
  p {
    margin: 0 0 8px 0;
    line-height: 1.6;
  }
  .img-center {
    text-align: center;
  }
  .img-left {
    float: left;
  }

  .img-right {
    float: right;
  }

  strong,
  b {
    font-weight: bold;
  }
  i,
  cite,
  em,
  var,
  address,
  dfn {
    font-style: italic;
  }
  u,
  ins {
    text-decoration: underline;
  }
  s,
  strike,
  del {
    text-decoration: line-through;
  }
  sub {
    vertical-align: sub;
  }
  sup {
    vertical-align: super;
  }
  sub,
  sup {
    font-size: smaller;
  }
  tt,
  code,
  kbd,
  samp {
    font-family: monospace;
  }
  blockquote {
    display: block;
    font-style: italic;
    font-family: Georgia, Times, 'Times New Roman', serif;
    padding: 2px 0;
    border-style: solid;
    border-color: #ccc;
    border-width: 0;
    margin: 1em 40px;
    padding-left: 20px;
    padding-right: 8px;
    border-left-width: 5px;
  }
  q {
    quotes: initial;
  }
  q::before {
    content: open-quote;
  }
  q::after {
    content: close-quote;
  }
  big {
    font-size: larger;
  }
  small {
    font-size: smaller;
  }
  .marker {
    background-color: Yellow;
  }

  .embedly-embed {
    max-width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
    color: #2f363b;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: subpixel-antialiased !important;
  }

  h1 {
    @extend .h1 !optional;
  }

  h2 {
    @extend .h2 !optional;
  }

  h3 {
    @extend .h3 !optional;
  }

  h4 {
    @extend .h4 !optional;
  }

  h5 {
    @extend .h5 !optional;
  }

  h6 {
    @extend .h6 !optional;
  }

  table {
    border-collapse: collapse;
    font-size: 1em;
    width: 100%;

    td,
    th {
      vertical-align: top;
    }

    thead td,
    th {
      font-weight: bold;
    }

    &[border] {
      td,
      th,
      thead td {
        border: 1px solid currentColor;
      }
    }

    &[border='0'] {
      &,
      td,
      th {
        border: none;
      }
    }
  }
}

@import 'legacy/stylesheets/helpers/_variables.scss';

.homepage-network__notifications {
  .homepage-notif {
    top      : $header-height;
    z-index  : map-get($zIndices, "toast");
    font-size: 1.3em;
    transition: top 0.5s ease;

    &--top-shifted {
      @if variable-exists(header_socials_height) {
        top: calc(#{$header_socials_height} + #{$header_height});
      }
    }

    @include media-query(palm) {
      @if variable-exists(header_mobile_padding) {
        top: calc(#{$header-height} + #{$header_mobile_padding}) !important;
      }
    }
  }
  .homepage-notif__close-button {
    position: absolute;
    top     : 10px;
    right   : 15px;
    font-weight: 200;
    font-size: 45px;
  }
}

.error_notification {
  background-color: transparentize($error-color, 0.8);
  border          : 2px solid transparentize($error-color, 0.4);
  color           : darken($error-color, 15%);
  padding         : 1em;
  border-radius   : 4px;
  margin-bottom   : 1em;
}

.homepage-notif {
  z-index    : map-get($zIndices, "toast");
  padding    : 10px;
  font-weight: 500;
  font-size  : 1.3em;
  color      : white;
  top        : 0;
  @include media-query(palm) { font-size: 1em; }
}

  .homepage-notif--success {
    background-color: rgba(5, 187, 36, 1);
  }
  .homepage-notif--error {
    background-color: $error-color;
  }

  .homepage-notif__close-button {
    position   : fixed;
    right      : 0.5em;
    font-size  : 2.6em;
    color      : white;
    top        : -5px;
    font-weight: 200;

    &:hover { color: white!important; }

    @include media-query(palm) {
      font-size  : 2.5em;
    }
  }

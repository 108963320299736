// News display on news index view

.news-show__cover {
  height: auto;

  @include media-query(lap-and-up) {
    height: 350px;
  }
}

.news-show__container {
  position: relative;
  background-color: white;
  line-height: 2em;
  font-size: 1.1em;
  margin-bottom: 50px;
  @extend .soft-double;
}

.news-show__header {
  @extend .bordered--bottom;
}

.news-show__title {
  font-size: 1.6em;
  @extend .flush;
}

.news-show__content--a11y {
  &.ckeditor-content--reset a:not(.button),
  a {
    color: var(--color-public-links);
    font-weight: 600;
    text-decoration: underline;
  }
  &.ckeditor-content--reset a:not(.button):hover,
  a:hover {
    color: var(--color-public-links-hover);
  }
}

// News display on homepage index view
.news {
  padding-bottom: 70px;

  .btn {
    background: rgba(255, 255, 255, 0.15);
    color: #464646;
    text-transform: uppercase;
    font-size: 1em;
    padding: 10px 25px 10px 25px;
    border-radius: 3px;
    position: relative;
    font-weight: 600;
    letter-spacing: 1px;
    transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);

    &:hover {
      box-shadow: none;
      background: rgba(255, 255, 255, 0.2);

      &:before {
        transform: scaleX(1);
      }
    }

    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.15);
      transition: 0.2s transform ease-out;
      transform: scaleX(0);
      transform-origin: 100% 50%;
    }
  }

  .default-border .btn {
    border: #464646 1px solid;

    &:before {
      background: rgba(42, 42, 42, 0.05);
    }
  }
}

.section-title {
  background-color: #ffffff;
  padding-bottom: 35px;
  margin-bottom: 25px;
}
.section-title .container--centered {
  padding: 0 10px;
}
.section-title h1 {
  margin-bottom: 0;
  color: #2f363b;
}

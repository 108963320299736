@use "sass:math";
@use "sass:map";

$font-primary: 'Inter', 'Nunito', Helvetica, Arial, sans-serif;
$lato-font: 'lato', Helvetica, Arial, sans-serif;
$header-font: Helvetica, Arial, sans-serif;
$header-font-bold: Helvetica, Arial, sans-serif;

/*
 * Height
 */
$header-height: 72px;
$menu-navigation-height: 44px;

$iphone-x-menu-height: 86px;

/*
 * Spacing
 */
$base-font-size: 13px !default;
$base-font-size-rem: 1rem !default;
$base-line-height: 16px !default;

$base-spacing-unit: 20px !default;
$half-spacing-unit: 12px !default;
$quarter-spacing-unit: math.div($half-spacing-unit, 2) !default;
$line-height-ratio: math.div($base-line-height, $base-font-size);

$iphone-x-menu-spacing: 26px;

/*
 * Others
 */
$brand-radius: 3px;

$zIndices: (
  /** main page elements `pinned` to top/bottom or centered (e.g. loaders) */
  "pinned": 1010,
  /** `sticky` main page elements that stay on-screen even while scrolling */
  "sticky": 1020,
  /** main page elements `positioned` on the screen (e.g. Release Rachel) */
  "positioned": 1030,
  /** darkened `backdrop` benhind dialogs, modals, and offcanvas elements */
  "backdrop": 1040,
  /** main page elements that live `offcanvas` and may animate into place */
  "offcanvas": 1050,
  /** `modal` elements that interrupt regular browsing and content flow */
  "modal": 1060,
  /** temporary overlay `popover` form controls in response to user actions */
  "popover": 1070,
  /** help text `tooltip` displayed when hovering over an element label */
  "tooltip": 1080,
  /** notification and alert `toast` elements that pop-up and disappear */
  "toast": 1090,
);

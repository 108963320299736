/**
 * General
 */
$body-background-color: var(--color-background);
$background-color: #f5f6f6;
$site-background-color: $background-color;
$background-color--beige: #fafafa;
$background-color--blue: #323a45;
$transparent_background_modal: rgba(0, 0, 0, 0.7);

/**
 * Colors
 */
$white: #ffffff !default;
$black: #2f363b !default;
$gray: #717273 !default;
$soft-gray: #999 !default;
$light-gray: #eeeeee !default;
$smooth-gray: #d7d7d7 !default;
$dark-gray: #353b42 !default;
$dark-half-gray: #666 !default;
$very-light-gray: #fcfcfc !default;
$super-blue: #00599d !default;
$blue: #257bb1 !default;
$yellow: #fed436 !default;
$green: #4bcc4b !default;
$dark-green: #62b165 !default;
$greenblue: #7fd0cd !default;
$orange: #f88942 !default;
$lightgreen: #7fd082 !default;
$red: #FD4329 !default;
$red-light: #FD4329 !default;
$twitter_blue: #02abee !default;
$link-color: #3078ca !default;
$error-color: #FD4329 !default;

/**
 * Buttons
 */
$btn-white-border: #d3d3d3 !default;
$btn-green: $green;
$btn--disabled: #cacaca !default;

/**
 * Borders
 */
$border-soft-gray: #dadada;

/**
 * Texts
 */
$text-soft-gray: #94989b;
$text-dark-gray: #2f363b;

/**
 * Social network official color
 */
$facebook-color: #3b5998 !default;
$twitter-color: #00aced !default;
$google-plus-color: #dd4b39 !default;
$youtube-color: #bb0000 !default;
$linkedin-color: #0077b5 !default;
$viadeo-color: #fff !default;
$flickr-color: #ff0084 !default;
$instagram-color: #517fa4 !default;
$dailymotion-color: #006894 !default;
$pinterest-color: #cb2027 !default;
$xing-color: #b0d400 !default;

/**
 * Accessiblity color
 */
$accessibilty-green: #118906 !default;

/**
 * DS colors
 */
$dove: #737373 !default;

@import 'legacy/stylesheets/helpers/_variables.scss';

.header-container {
  background: $header_bg_color;
  height: $header_height;
  z-index: map-get($zIndices, "sticky");
  position: fixed;
  width: 100%;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
  top: 0;
  transition: top 0.35s ease;

  .homepage-container {
    height: 100%;

    @media screen and (min-width: 768px) {
      max-width: 768px;
    }

    @media screen and (min-width: 1024px) {
      max-width: 1024px;
    }

    @media screen and (min-width: 1440px) {
      max-width: 1440px;
    }
  }

  &--top-shifted {
    top: $header_socials_height + 2;
  }
  &--immersive-menu {
    position: absolute;
    display: none;
  }

  @include media-query(palm) {
    padding-top: $header_mobile_padding !important;
    height: 92px !important;
    top: 0 !important;
  }

  &--socials {
    top: 0;
    height: 32px !important;
    overflow: hidden;
    transform: scaleY(1);
    transform-origin: top;
    transition: transform 0.35s ease;

    &.sticky-socials {
      height: 40px;
      transform: scaleY(0);
      transform-origin: top;
    }

    @include media-query(palm) {
      display: none;
    }

    .network-header__social-icon {
      font-size: 1.2em;
      display: inline-block;
      height: 100%;
      width: 32px;
      padding-left: 1px;
      color: white;
      text-align: center;
      position: relative;

      a {
        line-height: $header_socials_height;

        i {
          display: block;
          transform: translateY(0%);
          transition: transform 0.3s ease;
          color: #ffffff;
        }
      }

      .social-icon--hovered {
        color: #FD4329;
        position: absolute;
        top: -100%;
        justify-content: center;
        height: 100%;
        width: 100%;
      }

      &:hover i {
        transform: translateY(100%);
      }
    }
  }
}

.header__logo-container {
  display: inline-block;
  vertical-align: top;
}

.header__logo {
  height: 65px;
  vertical-align: top;
  padding: 8px 0;

  @include media-query(palm) {
    margin-top: 10px !important;
    padding: 0 0 0 20px !important;
    height: $header_height - 20px !important;
  }
}
.header__logo__po {
  height: 48px;
  vertical-align: middle;
  margin-top: 12px;
  margin-bottom: 12px;
}

.header__menu-container {
  display: inline-block;
  vertical-align: top;

  @include media-query(palm) {
    display: none;
  }
}

.header__menu {
  li {
    position: relative;
    display: inline-block;
    vertical-align: top;

    &.open .header--menu__submenus {
      display: inline-table;
    }

    &:hover,
    .header--menu__submenus {
      .header--menu__submenus {
        display: inline-table;
      }
    }

    a {
      line-height: $header_height;
      height: $header_height;
      color: $header_font_color;
      display: block;
      vertical-align: middle;
      padding: 0 10px;
      font-size: rem-calc(16px);
      font-weight: 600;

      span {
        &:after {
          position: absolute;
          left: 0;
          bottom: -8px;
          width: 100%;
          height: 4px;
          background: $header_font_color;
          transition: transform 0.3s cubic-bezier(0.9, 0.26, 0.58, 1);
          transform: scaleX(0);
          transform-origin: left 50%;
          border-radius: 2px;
          content: ' ';
        }
      }

      &.active span:after,
      &:hover span:after {
        transform: scaleX(1);
      }
    }
  }
}
.header--menu__submenus {
  display: none;
  width: auto;
  position: absolute;
  white-space: nowrap;
  padding: 0 12px;
  min-width: 160px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 6px;

  li {
    display: block;
    text-align: left;
    width: auto;

    a {
      position: relative;
      padding: 8.5px 0;
      line-height: 2;
      height: auto !important;

      @include media-query(palm) {
        line-height: $header_height;
      }
    }
  }
}

.header__buttons--right {
  @extend .float--right;
  @extend .text--right;

  margin-left: 20px;

  .login-link a {
    height: $header_height;
    line-height: $header_height;
    color: $header_font_color;
    font-size: rem-calc(16px);
    letter-spacing: 0.4px;
    font-weight: 600;
    margin-right: 16px;
    position: relative;

    &:hover {
      @extend .transition-all-150;
      color: darken($header_font_color, 20%);
    }

    &:after {
      position: absolute;
      left: 0;
      bottom: 19px;
      width: 100%;
      height: 4px;
      background: $header_font_color;
      transition: transform 0.3s cubic-bezier(0.9, 0.26, 0.58, 1);
      transform: scaleX(0);
      transform-origin: left 50%;
      border-radius: 2px;
      content: ' ';
    }

    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }
  }

  #header__buttons--right__flex {
    display: flex;
    align-items: center;
    height: $header_height;
  }
}

// More items dropdown
.dropdown-more-menu > a {
  line-height: $header_height;
  height: $header_height;
  color: white;
  display: block;
  font-size: rem-calc(16px);
  font-weight: 600;
  padding: 0 10px;
}
.dropdown-more-menu.open .dropdown-menu {
  display: inline-table;
}
.dropdown-more-menu .dropdown-menu {
  background-color: inherit;

  & > li:hover .header--menu__submenus {
    display: block;
  }
  li {
    position: relative;

    &.open .header--menu__submenus {
      display: inline-table;
    }
  }
  li:hover {
    background-color: inherit;
  }
  li a {
    color: white;
    vertical-align: middle;
    font-size: rem-calc(16px);
    font-weight: 600;
    display: block;

    span {
      &:after {
        position: absolute;
        left: 0;
        bottom: -8px;
        width: 100%;
        height: 4px;
        background: $header_font_color;
        transition: transform 0.3s cubic-bezier(0.9, 0.26, 0.58, 1);
        transform: scaleX(0);
        transform-origin: left 50%;
        border-radius: 2px;
        content: ' ';
      }
    }

    &.active span:after,
    &:hover span:after {
      transform: scaleX(1);
    }
  }

  .header--menu__submenus {
    right: 100%;
    top: 0;
  }
}

.js-menu-container,
.js-buttons-right-container {
  opacity: 0;
}

// Mobile menu
.toggle-mobile-menu {
  display: inline-block;
  margin-left: 16px;
  margin-right: 24px;

  @include media-query(lap-and-up) {
    display: none;
  }

  a,
  .toggle-mobile-menu-button {
    color: $header_font_color;
    font-size: 2.27em;
    line-height: 71px;
    border: 0;
    background: transparent;
    padding: 0 5px;
    margin: 0;
    vertical-align: bottom;
    width: 32px;

    i:before {
      @extend .transition-all-300;
    }

    i.icon-plus:before {
      transform: rotateZ(45deg);
      font-size: 1.12em;
    }
  }
}

.mobile__menu {
  margin-top: $header_height + $header_mobile_padding;
  background: $header_bg_color;
  transform: translateX(-50%);
  font-size: rem-calc(16px);
  line-height: 2em;
  position: fixed;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: map-get($zIndices, "offcanvas");
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;

  @extend .transition-all-300;

  a {
    color: white;
  }

  &.open {
    transform: translateX(0);
    display: block;
  }

  > ul {
    > li {
      position: relative;

      @extend .transition-all-150;

      &.toggle__sub-menu--open {
        .mobile__sub-pages-customizables {
          max-height: 5000px;
          transition: max-height 0.25s ease-in;
        }

        .toggle__sub-menu {
          transform: rotate(-90deg);
        }
      }

      a {
        padding: 8px rem-calc(52px) 8px rem-calc(18px);
        display: block;
        font-weight: 600;

        span {
          &:after {
            position: absolute;
            left: 0;
            bottom: -8px;
            width: 100%;
            height: 4px;
            background: $header_font_color;
            transition: transform 0.3s cubic-bezier(0.9, 0.26, 0.58, 1);
            transform: scaleX(0);
            transform-origin: left 50%;
            border-radius: 2px;
            content: ' ';
          }
        }

        &.active span:after,
        &:hover span:after {
          transform: scaleX(1);
        }
      }
    }

    .mobile-sign-up-container {
      width: min-content;
      margin: 0 auto;
    }

    .no-padding--x {
      a {
        padding: 8px 0px;
      }
    }
  }
}
.mobile__sub-pages-customizables {
  transition: max-height 0.15s ease-out;
  overflow: hidden;
  font-size: 0.8em;
  margin-left: 1.25em;
  max-height: 0;

  > li > a {
    font-size: rem-calc(14px);
  }

  // Must be set at the end of close transition, so that screen readers will ignore it
  &.mobile__sub-pages-customizables--closed {
    display: none;
  }
}
.mobile__menu {
  .toggle__sub-menu {
    display: block;
    position: absolute;
    right: 0;
    top: 14px;
    font-size: inherit;
    font-weight: 300;
    cursor: pointer;
    color: white;
    min-width: 44px;
    padding: 0 10px;
    text-align: center;
    font-family: serif;
    background: none;
    border: none;
    transform: rotate(90deg);

    i {
      font-size: 1.175rem;
      line-height: inherit;
    }
  }
}

.network-header__social-wrapper {
  overflow: hidden;
}

.header-container--socials {
  // Force overflow and put higher z-index for socials and language header
  overflow: visible;
  z-index: calc(map-get($zIndices, "sticky") + 1);
}

.header__language-picker {
  vertical-align: middle;
  display: inline-block;
}

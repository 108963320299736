.network-sidebar {
  @include media-query(palm) {
    border-bottom: 1px solid #CECECE;
    overflow-x: auto;
    top: auto !important;
    width: 100% !important;
    z-index: 99;
    position: initial !important;

    @if variable-exists(bg-color--soft-white) {
      background-color: $bg-color--soft-white;
    }
    @if variable-exists(background-color) {
      background-color: $background-color;
    }
  }

  #network-sidebar-news {
    li a {
      line-height: 1.2rem;
    }

    @include media-query(palm) {
      height: auto;
    }
  }
}
.network-sidebar__menu {
  padding: 10px 0;
  font-size: 1rem;

  @include media-query(palm) {
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    line-height: 0em;

    li {
      vertical-align: middle;
      display: inline-block;
    }
    a {
      color: $gray !important;
      letter-spacing: 0.5px;
      font-size: 1em;
      background-color: transparent;
      line-height: 1em;
      padding: 5px 10px;
      margin: 0;

      &:hover {
        color: $dark-gray !important;
      }
    }
  }
  .active {
    color: $dark-gray !important;
    font-weight: 600;
  }
}

.network-sidebar__title-section {
  font-size: 1.2rem;

  @extend .push--bottom;

  @include media-query(palm) {
    display: none;
  }
}

.container--right {
  @include media-query(palm) {
    margin-top: 25px;
  }
}

#network-sidebar-news {
  height: 35vh;
  overflow-y: auto;
  padding: 2px;
}

.homepage__pagination {
  margin-top: 20px;
  text-align: center;
}
  .homepage__pagination a, .current, .previous_page, .next_page {
    display      : inline-block;
    padding      : 5px 12px;
    border       : 1px solid #ebebec;
    color        : $gray;
    cursor       : pointer;
    border-radius: 3px;

    &:hover {
      @extend .transition-all-300;
      border-color: #bdbdbd;
    }
  }
    .homepage__pagination .current {
      border-color: #bdbdbd;
    }

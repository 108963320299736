@import 'legacy/stylesheets/helpers/_variables.scss';

$map-container: 675px;
@if variable-exists(menu-navigation-height)  {
  .map-mode {
    .total-items, .map-items__container, .map, .map-view__filters {
      @extend .transition-all-150;
    }
    &.map-mode--half {
      .map-move-control__container { display: none; }
      .item-ressource-view.one-half {
        width: 100% !important;
      }
      .bg-white-faded, .total-items, .map-items__container {
        width: $map-container * 0.5!important;
      }

      .map, .map-view__filters {
        left: $map-container * 0.5;
      }
    }

    .map-items__container {
      z-index    : 2;
      box-shadow : 1px -8px 15px rgba(0, 0, 0, 0.21);
      width      : $map-container;

      @include media-query(ipad) { width: 100%; }
      @include media-query(palm) { width: 100%; }

      .bg-white-faded {
        position: fixed;
        bottom  : 0;
        width   : $map-container;

        @include media-query(ipad) { width: 100%; }
        @include media-query(palm) { width: 100%; }
      }
    }
    .map {
      bottom  : 0;
      left    : $map-container;
      position: fixed;
      right   : 0;
      top     : $header-height + $menu-navigation-height;
      z-index : 1;

      @include media-query(ipad) { display: none; }
      @include media-query(palm) { display: none; }
    }

    .map-view__filters {
      z-index : calc(map-get($zIndices, "pinned") + 1);
      position: fixed;
      top     : $header-height + $menu-navigation-height;
      left    : $map-container;
    }

    .map-move-control__container {
      display       : inline-block;
      vertical-align: middle;
      margin-right  : 10px;

      @include media-query(ipad) { display: none; }
      @include media-query(palm) { display: none; }

      label {
        vertical-align: top;
      }
    }

    .total-items {
      position  : fixed;
      top       : $header-height + $menu-navigation-height;
      padding   : 10px 0;
      left      : 0;
      right     : 0;
      width     : $map-container;
      z-index   : calc(map-get($zIndices, "pinned") + 2);
      background: $background-color;

      @include media-query(ipad) {
        width: 100%;
        top  : 55px;
      }
      @include media-query(palm) {
        position: static;
        width   : auto;
      }
    }

    .items-wrapper {
      padding-top: 32px;
      @include media-query(palm) {
        padding-top: 0;
        background-color: #E6E9F3;
      }
    }

  }

  .map-view__filters--mobile {
    position  : static !important;
    margin-top: 31px;

    .item-filters-view {
      @extend .bordered--ends;
      @extend .push--bottom;
      box-shadow: none;
      position  : static !important;
      width     : 100%;
    }
  }

  // InfoBox Cluster
  .search-filters-map {
    position  : relative;
    left      : 10px;
    top       : 10px;
    width     : 40%;
    z-index   : map-get($zIndices, "pinned");
    background: #fff;
  }
}

// Markers
img.leaflet-marker-icon.dot.leaflet-zoom-animated.leaflet-interactive {
  border-radius: 50%;
  box-shadow   : 1px 1px 1px rgba(0, 0, 0, 0.23);
  border       : 4px solid white;
  @extend .transition-all-150;

  &:hover {
    border-width: 0;
    z-index     : calc(map-get($zIndices, "pinned") + 3);
  }
}

.leaflet-popup-content-wrapper { border-radius: 3px; }
.leaflet-popup-content { min-width: 300px;  }
.leaflet-popup-content img { width: 100%; }

// Simple marker
.simple-marker {
  background-color: $blue;
  background-clip : padding-box;
  box-shadow      : 1px 1px 3px rgba(109, 108, 108, 0.85);
  border          : 15px solid rgba(255, 255, 255, 0.66);
  border-radius   : 50%;
}

// Marker cluster
.marker-cluster-small, .marker-cluster-medium, .marker-cluster-large  {
  background-color: white;
  box-shadow      : 1px 1px 1px rgba(0, 0, 0, 0.23);

  span {
    font-weight: 600;
    color      : #404040;
  }
}

.marker-cluster-small div { background-color: #94D266; }
.marker-cluster-medium div { background-color: #FFD737; }
.marker-cluster-large div { background-color: #FFA34D; }

// InfoBox
.leaflet-container a.leaflet-popup-close-button { color: white; }
.leaflet-popup-content-wrapper { padding: 0; }
.leaflet-popup-content {
    margin    : 0;
    width     : 350px!important;
    max-height: 290px;
    overflow  : hidden;

    .cover-picture {
      height             : 150px;
      background-repeat  : no-repeat;
      background-size    : 100%;
      background-position: top center;
      background-size    : cover;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }

    .profile-picture {
      left            : 12px;
      top             : 15px;
      background-color: white;
      border-radius   : 3px;
      display         : inline-block;
      margin          : -52px 2px 0 -3px;
      position        : relative;
      vertical-align  : bottom;
      z-index         : 1;

      img {
        display      : block;
        height       : 72px;
        width        : 72px;
        border       : 3px solid white;
        border-radius: 5px;
      }
    }

    .content {
      padding    : 0;
      margin-left: 90px;
      position   : relative;
      top        : -11px;
    }

      .username a { color: $text-dark-gray; }

      .bio {
        margin-top: 2px;
        font-size: 1em;
        word-wrap: break-word;
        color    : $text-soft-gray;
      }

}

.social-button {
  color: #fff !important;
}
.social-button__linkedin {
  background: #0077b5;

  &:hover {
    background: #0074ab;
  }
}
.social-button__linkedin-trademark {
  padding-top: 5px;
  margin-left: -2px;
}
.social-button__facebook {
  background: #1877f2;

  &:hover {
    background: darken(#1877f2, 5);
  }
}
.social-button__xing {
  background: #006567;

  &:hover {
    background: #00657d;
  }
}
.social-button__google {
  background: #db4437;

  &:hover {
    background: #bb4437;
  }
}
.social-button__apple {
  background: #000;

  &:hover {
    background: #000;
  }
}


.custom-attr__group--hide {
  border: 0;
}

.custom-attr__group--show {
  border: 2px solid $light-gray;
  padding: 10px;
  margin-bottom: 24px;

  legend {
    font-size: 1.2em;
    font-weight: 600;
    color: $black;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.cookies {
  .cookies-eu {
    @include media-query(desk) {
      padding: 0 90px;
    }

    @include media-query(palm) {
      padding-bottom: 20px;
    }

    .cookies-eu-button-holder {
      @include media-query(portable) {
        float: inherit;
      }
    }
  }
}

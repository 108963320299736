$table-header-background: #f2f2f2;
$table-border-color: #CECECE;
table {
  width: 100%;
  // This prevent from HTML blocks to break design
  iframe, img:not([width]) {
    max-width: 100%;
  }
}

%table-thead {
  border-bottom: 1px solid $table-border-color;
  background   : $table-header-background;
}

%table-th {
  font-weight: 600;
  padding    : 0.9em 0.8em;
  i {
    margin-left: 0.4em;
  }
}

%table-td {
  vertical-align: middle;
  padding    : 0.5em 0.8em;
  line-height: 1.5;
  font-weight: 400;
  color      : #777;
}

.table--data {
  font         : inherit;
  font-size    : 0.9em;
  text-align   : left;
  margin-bottom: $base-spacing-unit;
  border       : 1px solid #DDD;
  border-radius: 2px;

  thead { @extend %table-thead; }

  th { @extend %table-th; }

  td { @extend %table-td; }

  td { border-bottom: 1px solid $table-border-color; }
  tr:last-child td { border: none; }

  tr:hover td { background: #F8F8F8; }
}

.simple-table {
  th, td {
    padding    : 6px;
    line-height: 1.2;
  }
  th { font-weight: bold; }
  td { vertical-align: top; }
  tfoot tr {
    td, th {
      border-top: 1px solid #CCC;
    }
  }
}

.white-table {
  tbody    { background: white; }
  tr:hover { background: #FEFEFE; }
  tr { border-bottom: 2px solid $site-background-color; }
  th, td { padding: 1em; }

  th { text-transform: uppercase; }

  img {
    max-height    : 6em;
    vertical-align: middle;
  }
}

.sortable-table {
  th.header {
    cursor : pointer;
    display: table-cell;
  }
}

//--------- JQuery.tablesorter.js CSS ------------------

table.tablesorter {
  font-family:arial;
  text-align: left;
}

table.tablesorter thead tr th, table.tablesorter tfoot tr th {
}

table.tablesorter thead tr .header {
  //background-image: url("libs/tablesorter/bg.gif");
  background-repeat: no-repeat;
  background-position: center left;
  cursor: pointer;
  padding-left: 18px;
}

table.tablesorter thead tr .headerSortUp {
  //background-image: url("libs/tablesorter/asc.gif");
}

.headerSortDown .icon-chevron-down:before {
  @extend .icon-chevron-up;
}

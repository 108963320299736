/**
 * Headings 1–6.
 */

// Predefine the variables below in order to alter and enable specific features.
$heading-size-1: 2.1rem !default;
$heading-1-line-height: 1.4rem !default;
$heading-size-2: 1.5rem !default;
$heading-2-line-height: 1.4rem !default;
$heading-size-3: 1.4rem !default;
$heading-3-line-height: 1.3rem !default;
$heading-size-4: 1.3rem !default;
$heading-4-line-height: 1.2rem !default;
$heading-size-5: 1.2rem !default;
$heading-5-line-height: 1.2rem !default;
$heading-size-6: 1.1rem !default;
$heading-6-line-height: 1.1rem !default;

.h1, .h2, .h3, .h4, .h5, .h6 {
  font-weight: 400;
  color: #2f363b;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: subpixel-antialiased !important;
}

.h1,
.alpha {
  font-size: $heading-size-1;
  margin-bottom: $heading-size-1;
  line-height: 1.3em;
}
.palm--h1 {
  @include media-query(palm) {
    font-size: $heading-size-1;
    margin-bottom: $heading-size-1;
  }
}

.h2,
.beta {
  font-size: $heading-size-2;
  margin-bottom: $heading-size-2;
}
.palm--h2 {
  @include media-query(palm) {
    font-size: $heading-size-2;
    margin-bottom: $heading-size-2;
  }
}

.h3,
.gamma {
  font-size: $heading-size-3;
  margin-bottom: $heading-size-3;
}
.palm--h3 {
  @include media-query(palm) {
    font-size: $heading-size-3;
    margin-bottom: $heading-size-3;
  }
}

.h4,
.delta {
  font-size: $heading-size-4;
  margin-bottom: $heading-size-4;
  line-height: 1.5rem;
}
.palm--h4 {
  @include media-query(palm) {
    font-size: $heading-size-4;
    margin-bottom: $heading-size-4;
  }
}

.h5 {
  font-size: $heading-size-5;
  margin-bottom: $heading-size-5;
}
.palm--h5 {
  @include media-query(palm) {
    font-size: $heading-size-5;
    margin-bottom: $heading-size-5;
  }
}

.h6 {
  font-size: $heading-size-6;
  margin-bottom: $heading-size-6;
}
.palm--h6 {
  @include media-query(palm) {
    font-size: $heading-size-6;
    margin-bottom: $heading-size-6;
  }
}

@import 'legacy/stylesheets/helpers/_variables.scss';

.networks-input.ui-tokensinput {
  ul, input.ui-autocomplete-input {
    width: 50%;
  }
  ul {
    float     : right;
    margin    : 0;
    text-align: right;
    right     : 0;
    list-style: none;
    border    : 1px solid $gray;
    span, .remove {
      vertical-align: middle;
    }
    .remove {
      font-weight : bold;
      padding-left: 5px;
    }
  }
}
.ui-autocomplete.ui-menu {
  list-style   : none;
  margin       : 0;
  display      : block;
  margin-top   : -3px;
  z-index      : map-get($zIndices, "popover") !important;
  border-radius: 0 0 3px 3px;
  box-shadow   : 0 1px 1px rgba(0, 0, 0, 0.25), 0 0 0px rgba(0, 0, 0, 0.35);
}

.ui-menu .ui-menu-item {
  margin : 0;
  zoom   : 1;
  float  : left;
  clear  : left;
  width  : 100%;
}

.ui-menu .ui-menu-item a {
  text-decoration: none;
  display        : block;
  line-height    : 1.5;
  zoom           : 1;
  font-size      : 1.2em;
}
  .ui-state-focus a {
    color: white;
  }

.ui-menu .ui-menu-item a.ui-state-hover,
.ui-menu .ui-menu-item a.ui-state-active {
  font-weight     : normal;
  border          : none;
  background-color: #EEE;;
}

.ui-autocomplete {
  position  : absolute;
  cursor    : default;
  background: white;
}

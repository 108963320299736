.card-item {
  @extend .white-box;
}
.card-item--hoverable {
  &, .card-item__highlightable { @extend .transition-all-300; }
  &:hover{
    .card-item__highlightable { color: $blue; }
    box-shadow: 0px 0px 15px #EEE;
  }
}
  .card-item--not-styled-on-palm {
    @include media-query(palm) {
        background: inherit;
        border    : none;
    }
  }

  .card-item__title {
    font-size  : 1.3em;
    color      : #333333;
    font-weight: bold;
  }

  .card-item__footer {
    color           : #666666;
    border-top      : 1px solid #DEDEDE;
    background-color: #F7F7F7;
  }

  .card-item__separator {
    width      : 2em;
    padding-top: 0.7em;
    margin-top : 0.7em;
    border-top : 1px solid $light-gray;
  }

  .card-item__avatar {
    height: 30px;
    width : 30px;
  }

// Card item view (map mode)
.card-user-view, .card-event-view  {
  position: relative;
  cursor  : pointer;
}
  .card-user-view__no-location {
    position     : absolute;
    height       : 10px;
    width        : 10px;
    border-radius: 10px;
    background   : #607D8B;
    left         : 15px;
    top          : 5px;
  }
  .card-user-view__no-location--topleft-corner {
    top: 3px;
    left: 3px;
  }
  .card-user-view__username {
    margin-bottom: 1px;
    font-size    : 1.15em;
  }

  .card-user-view__picture {
    max-width                : 100%;
    width                    : 100px;
    height                   : 100px;
    overflow                 : hidden!important;
    border-top-left-radius   : $brand-radius;
    border-bottom-left-radius: $brand-radius;
    display                  : inline-block;
    vertical-align           : sub;
    img { width: 100%; }
  }

  .card-user-view__desc {
    p {
      font-size: .99em;
      margin   : 0;
      padding  : 2px 0;

      &:first-child { color: $text-soft-gray; }
    }
  }

  .card-user-view__address {
    position: absolute;
    bottom  : 0;

    span {
      color    : $text-soft-gray;
      font-size: .9em;
    }
  }

.feed-item__content {
  margin-top: 1em;

  .card-event-view {
    min-height: auto;
  }
  .card-event-view__title {
    height: auto;
  }
  .users-listing {
    margin-bottom: 0 !important;
  }
}
.card-event-view  {
  min-height: 10em;

  > div { height: inherit; }
}

  .card-event-view__title {
    height        : 35px;
    vertical-align: inherit!important;
  }
  .card-event-view__desc {
    color: $text-soft-gray;
    &:hover {
      text-decoration: none !important;
    }

  }
  .card-event-view__desc-content {
    height: 55px;

    p {
      margin    : 0!important;
      text-align: left!important;
    }
  }

a[disabled] {
    pointer-events: none;
    cursor: default;
}
a {
    text-decoration: none;
    cursor         : pointer;
    img {
        vertical-align: middle;
    }
}

.muted-link {
    text-decoration: none!important;
    color: inherit;
    &:hover {
        color: $link-color;
    }
}
.semi-muted-link {
    color          : inherit;
    text-decoration: none!important;
    &:hover {
        text-decoration: underline!important;;
        color          : $link-color;
    }
}

/**
 * Icon size
 */
.icon-size--050 { font-size: 0.5rem; }
.icon-size--070 { font-size: 0.7rem; }
.icon-size--150 { font-size: 1.5rem; }
.icon-size--200 { font-size: 2rem; }
.icon-size--250 { font-size: 2.5rem; }
.icon-size--300 { font-size: 3rem; }
.icon-size--400 { font-size: 4rem; }
.icon-size--500 { font-size: 5rem; }
.icon-size--600 { font-size: 6rem; }

.palm--icon-size--070 { @include media-query(palm) { font-size: 0.7rem; } }
.palm--icon-size--050 { @include media-query(palm) { font-size: 0.5rem; } }
.palm--icon-size--150 { @include media-query(palm) { font-size: 1.5rem; } }
.palm--icon-size--200 { @include media-query(palm) { font-size: 2rem;   } }
.palm--icon-size--250 { @include media-query(palm) { font-size: 2.5rem; } }
.palm--icon-size--300 { @include media-query(palm) { font-size: 3rem;   } }
.palm--icon-size--400 { @include media-query(palm) { font-size: 4rem;   } }
.palm--icon-size--500 { @include media-query(palm) { font-size: 5rem;   } }
.palm--icon-size--600 { @include media-query(palm) { font-size: 6rem;   } }

%icon--badge, .icon--badge {
  @extend .icon-size--150;
  vertical-align: middle;
}

.icon--absolute-badge {
  @extend %icon--badge;
  position: absolute;
  bottom  : -0.5em;
  right   : -0.5em;
}

.membership-status-icon {
  width        : 15px;
  height       : 15px;
  border-radius: 50%;

  &.membership-status-icon_green { background-color: $green; }
  &.membership-status-icon_red { background-color: $red; }
}


// corner on the top left of profile picture if deceased
.deceased-wrapper {
    overflow        : hidden;
    position        : absolute;
    top             : 0px;
    left            : 0px;
    width           : 100%;
    height          : 100%;
    background-color: rgba(255, 255, 255, 0.35);

  .corner {
    -webkit-transform: rotate(-45deg);
    -moz-transform   : rotate(-45deg);
    -ms-transform    : rotate(-45deg);
    -o-transform     : rotate(-45deg);
    transform        : rotate(-45deg);
    position         : relative;
    background-color : #404040;
    padding          : 3px 0;
    top              : 12px;
    left             : -12px;
    width            : 49px;
    z-index          : 2;
  }

  // User search
  &.deceased-wrapper--min .corner {
    padding: 2px 0;
    top    : 10px;
    left   : -12px;
    width  : 35px;
  };

  // User profile
  &.deceased-wrapper--big .corner {
    padding: 8px 0;
    top    : 30px;
    left   : -80px;
    width  : 185px;
  }
}

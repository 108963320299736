@charset 'UTF-8';

// Packages
@import 'select2/dist/css/select2.css';
@import 'libs/tooltip';
@import 'leaflet/dist/leaflet.css';
@import 'leaflet.markercluster/dist/MarkerCluster.css';
@import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
@import 'responsive-slides/responsiveslides.css';

// Reset
@import 'base/reset';

// Fonts
@import 'KitIconFont';
@import 'fonts/inter';
@import 'fonts/nunito';

// Variables
@import 'helpers/colors';
@import 'helpers/variables_hp_network';

// Mixins
@import 'mixins/shadow';

// Libs
@import 'libs/inuit/tools.responsive';
@import 'libs/inuit/responsive-helpers';
@import 'libs/csswizardry-grids';
@import 'libs/foundation/components/reveal';
@import 'libs/typeahead';
@import 'libs/jquery.fancybox';

// Base
@import 'base/containers';
@import 'base/headings';
@import 'base/forms';
@import 'base/links';
@import 'base/paragraphs';
@import 'base/table';
@import 'base/images';

// Helpers
@import 'helpers/animations';
@import 'helpers/autocomplete';
@import 'helpers/borders';
@import 'helpers/background';
@import 'helpers/display';
@import 'helpers/checkbox';
@import 'helpers/datepicker';
@import 'helpers/dropdown';
@import 'helpers/icons';
@import 'helpers/font_helpers';
@import 'helpers/input_textarea';
@import 'helpers/select2';
@import 'helpers/spacing';
@import 'helpers/typeahead';
@import 'helpers/tooltip';
@import 'helpers/flex';
@import 'helpers/scroll';
@import 'helpers/apple_system_footnote';

// Layouts
@import 'layout/global';

// Partials
@import 'partials/objects/slider';
@import 'partials/generic/helpers';
@import 'partials/base/links';
@import 'partials/base/inputs';
@import 'partials/objects/autocomplete';
@import 'partials/objects/boxes';
@import 'partials/objects/notif';

// Components
@import 'components/modal_preview';
@import 'components/card_item';
@import 'components/flexbox';
@import 'components/tag';
@import 'components/cover_bloc';
@import 'components/cover_bloc_event';
@import 'components/cover_bloc_news';
@import 'components/users_listing_horizontal';
@import 'components/homepage_network/button';
@import 'components/homepage_network/skip_to_content';
@import 'components/homepage_network/header';
@import 'components/homepage_network/footer';
@import 'components/homepage_network/sidebar';
@import 'components/homepage_network/pagination';
@import 'components/homepage_network/separator';
@import 'components/social_buttons';
@import 'components/map';
@import 'components/user_item';
@import 'components/custom_attr';
@import 'components/ckeditor_content';
@import 'components/alert';
@import 'components/search_job';
@import 'components/filters';
@import 'components/loader';
@import 'components/homepage/cookies';

// Pages
@import 'pages/homepage_network/homepage';
@import 'pages/homepage_network/news';
@import 'pages/homepage_network/events';
@import 'pages/news.scss'; // @TODO

html,
body {
  display: flex;
  flex-direction: column;
  font-family: $font-primary;
  color: $dark-gray;
  min-height: 100%;
}

.social--push-bottom {
  margin-bottom: 15px;
}

b,
strong {
  font-weight: bold;
}
em {
  font-style: italic;
}
